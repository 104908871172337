import { TooltipComponent } from '@syncfusion/ej2-react-popups'
import React from 'react'

const ActionButton=({title,onClick,textColor,icon,color})=>{
    return (
      <TooltipComponent content={title} position='BottomCenter'>
    <button onClick={onClick}
     className={`${color} relative ${textColor} text-xl rounded-full p-3 hover:shadow-lg`} >
    
    {icon}
    
    </button>
    </TooltipComponent>
    )
}

export default ActionButton