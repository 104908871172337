import React from 'react'
import { BsFillBuildingFill } from 'react-icons/bs'
import { FaExclamation, FaRegMoneyBillAlt } from 'react-icons/fa'
import { FiUsers } from 'react-icons/fi'
import { NavLink } from 'react-router-dom'
import { useStateContext } from '../../contexts/ContextProvider'
import { BiTransferAlt } from 'react-icons/bi'
import { Bs7Circle } from "react-icons/bs";
import { MdEditNote } from "react-icons/md";
import { PiNotePencilBold, PiStudentBold } from "react-icons/pi";
import { BsCalendar2Range } from "react-icons/bs";
import { FaRegFileAlt } from "react-icons/fa";
import { LuMousePointerClick } from 'react-icons/lu'

const DrEtudeRole = () => {
    const activeLink = 'flex items-center gap-5 pl-4 pt-3  bp-3 rounded-lg text-white bg-blue-600 text-md m-2';
    const normalLink = 'flex items-center gap-5 pl-4 pt-3  bp-3 rounded-lg  text-md text-gray-700 dark:text-gray-200 dark:hover:text-black hover:bg-light-gray m-2';
    const { setActiveMenu, screenSize, currentColor } = useStateContext();


    const handleCloseSidebar = () => {
        if (screenSize <= 900) {
            setActiveMenu(false);

        }
    }
    return (
        <div >

            <div>
                <NavLink to={`/classes`} key='classes' onClick={handleCloseSidebar}
                    className={({ isActive }) =>
                        isActive ? activeLink : normalLink
                    } style={({ isActive }) => isActive ? { backgroundColor: `${currentColor}` } : { backgroundColor: 'white' }}>
                    <BsFillBuildingFill />
                    <span className='capitalize mb-2'>
                        Classes
                    </span>
                </NavLink>
                <NavLink to={`/inscriptions`} key='inscriptions' onClick={handleCloseSidebar}
                    className={({ isActive }) =>
                        isActive ? activeLink : normalLink
                    } style={({ isActive }) => isActive ? { backgroundColor: `${currentColor}` } : { backgroundColor: 'white' }}>
                    <PiStudentBold />
                    <span className='capitalize mb-2'>
                        Inscriptions
                    </span>
                </NavLink>
                <NavLink to={`/absences`} key='absences' onClick={handleCloseSidebar}
                    className={({ isActive }) =>
                        isActive ? activeLink : normalLink
                    } style={({ isActive }) => isActive ? { backgroundColor: `${currentColor}` } : { backgroundColor: 'white' }}>
                    <LuMousePointerClick />
                    <span className='capitalize mb-2'>
                        Absences
                    </span>
                </NavLink>
                <NavLink to={`/observations`} key='observations' onClick={handleCloseSidebar}
                    className={({ isActive }) =>
                        isActive ? activeLink : normalLink
                    } style={({ isActive }) => isActive ? { backgroundColor: `${currentColor}` } : { backgroundColor: 'white' }}>
                    <FaExclamation />
                    <span className='capitalize mb-2'>
                        Observations
                    </span>
                </NavLink>
                <NavLink to={`/examens`} key='examens' onClick={handleCloseSidebar}
                    className={({ isActive }) =>
                        isActive ? activeLink : normalLink
                    } style={({ isActive }) => isActive ? { backgroundColor: `${currentColor}` } : { backgroundColor: 'white' }}>
                    <MdEditNote />
                    <span className='capitalize mb-2'>
                        Examens
                    </span>
                </NavLink>
                <NavLink to={`/devoirs`} key='devoirs' onClick={handleCloseSidebar}
                    className={({ isActive }) =>
                        isActive ? activeLink : normalLink
                    } style={({ isActive }) => isActive ? { backgroundColor: `${currentColor}` } : { backgroundColor: 'white' }}>
                    <PiNotePencilBold />
                    <span className='capitalize mb-2'>
                        Devoirs
                    </span>
                </NavLink>
                <NavLink to={`/emploies`} key='emploies' onClick={handleCloseSidebar}
                    className={({ isActive }) =>
                        isActive ? activeLink : normalLink
                    } style={({ isActive }) => isActive ? { backgroundColor: `${currentColor}` } : { backgroundColor: 'white' }}>
                    <BsCalendar2Range />
                    <span className='capitalize mb-2'>
                        Emploies
                    </span>
                </NavLink>
                <NavLink to={`/coefficients`} key='coefficients' onClick={handleCloseSidebar}
                    className={({ isActive }) =>
                        isActive ? activeLink : normalLink
                    } style={({ isActive }) => isActive ? { backgroundColor: `${currentColor}` } : { backgroundColor: 'white' }}>
                    <Bs7Circle />
                    <span className='capitalize mb-2'>
                        Coefficients
                    </span>
                </NavLink>
                <NavLink to={`/bulletins`} key='bulletins' onClick={handleCloseSidebar}
                    className={({ isActive }) =>
                        isActive ? activeLink : normalLink
                    } style={({ isActive }) => isActive ? { backgroundColor: `${currentColor}` } : { backgroundColor: 'white' }}>
                    <FaRegFileAlt />
                    <span className='capitalize mb-2'>
                        Bulletins
                    </span>
                </NavLink>
            </div>
        </div>
    )
}

export default DrEtudeRole