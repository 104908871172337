import React from 'react'

const Button = ({ icon,width, color, size, text,textColor, onClick }) => {
  return (
    // <button type='button' style={{ backgroundColor:bgColor,color,borderRadius }} 
    // className={`text-${size} p-3 hover:drop-shadow-xl`}>
    //   {text}
    // </button>"bg-blue-500 hover:shadow-xl text-white font-bold py-2 px-2  rounded inline-flex items-center"
    <button  onClick={onClick}  className={`${width} justify-center ${color} text-${size} hover:shadow-xl ${textColor} font-bold py-2 px-2 rounded inline-flex items-center`}>
      {icon}
      <span className='ml-1'>{text}</span>
    </button>
  )
}

export default Button
