import React, { useEffect, useRef, useState } from 'react'
import { Button, Header, Input } from '../../components'
import { BsPlusCircleFill, BsTrash } from 'react-icons/bs';
import { Link, useNavigate, useParams } from 'react-router-dom';
import axiosClient from '../../axios-client';
import Loading from '../../components/Loading';
import { useStateContext } from '../../contexts/ContextProvider';
import { AiTwotoneEdit } from 'react-icons/ai';
import ActionButton from '../../components/ActionButton';
import { MdOutlineAccountBalanceWallet } from "react-icons/md";
import { IoIosSearch } from 'react-icons/io';
import Select from 'react-select';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { FaCheckCircle, FaEdit, FaEye, FaRegFileExcel } from 'react-icons/fa';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import AddDevoir from './AddDevoir';
import Alert from '../../components/Alert';
import EditDevoir from './EditDevoir';
import { TbPencilCheck, TbPencilStar } from 'react-icons/tb';
import { GiCancel } from 'react-icons/gi';
import { FaFeatherPointed } from 'react-icons/fa6';
import { PiNotePencilBold } from 'react-icons/pi';
import { RiEmotionHappyFill, RiEmotionUnhappyFill } from 'react-icons/ri';
import EditNoteDevoir from './EditNoteDevoir';

const VoirDevoir = ({ selectedDevoir, onClose }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [devoir, setDevoir] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);
  const { setToken, user } = useStateContext();

  const [notes, setNotes] = useState([]);
  const [selectedNote, setSelectedNote] = useState(null);


  const [eleves, setEleves] = useState([]);
  const [selectedEleve, setSelectedEleve] = useState(null);
  const [inputValueEleve, setValueEleve] = useState('');

  const [showEditNote, setShowEditNote] = useState(false);

  const tableRef = useRef(null);
  const [req, setReq] = useState({
    devoirId: selectedDevoir.id,
    note: '',
    observation: '',
    eleve: '',
  });





  const handleChangeEleve = value => {
    setSelectedEleve(value);
    setReq({ ...req, eleve: value.id });


  }

  const handleInputChangeEleve = value => {
    setValueEleve(value);
  };





  const getEleves = () => {
    axiosClient.get('/eleves/' + selectedDevoir.classe.id)
      .then((data) => {
        setEleves(data.data.eleves);
      }).catch(err => {
        navigate('/500');
      })
  }
  const getDevoir = () => {
    setLoading(true);
    axiosClient.get('/devoir/' + selectedDevoir.id)
      .then((data) => {
        setDevoir(data.data.devoir);
        setLoading(false);
      }).catch(err => {
        const response = err.response;
        if (err.response.status === 401) {
          setToken(null);
        } else {
          if (response.status === 422) {
            setErrors(response.data.errors);

          }
          else {
            if (response.status === 403) {
              navigate('/403');
            } else {
              if (response.status === 404) {
                navigate('/404');
              }
              else {
                navigate('/500');
              }
            }
          }
        }
        setLoading(false);
      })

  }

  const supprimer = (note) => {
    Swal.fire({
      title: "Confirmation",
      text: "Etes vous sûre de supprimer cette element ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#2196F3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui!",
      cancelButtonText: "Non!"
    }).then((result) => {
      if (result.isConfirmed) {
        axiosClient.delete('/noteDevoir/' + note.id)
          .then(response => {
            toast.success('Opération effectuée avec succès!', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            getDevoir();
          }).catch(err => {
            const response = err.response;
            if (err.response.status === 401) {
              setToken(null);
            } else {
              if (response.status === 422) {
                setErrors(response.data.errors);
              }
              else {
                if (response.status === 403) {
                  navigate('/403');
                } else {
                  if (response.status === 404) {
                    navigate('/404');
                  }
                  else {
                    navigate('/500');
                  }
                }
              }
            }

          })
      }
    });
  }

  const saveNote = () => {

    setErrors(null);
    setLoading(true);
    axiosClient.post('/noteDevoir/save', req)
      .then((response) => {
        setLoading(false);
        setErrors(null);
        toast.success('Opération effectuée avec succès!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setSelectedEleve(null);
        setReq({ ...req, note: '', eleve: '' });
        getDevoir();
      }).catch(err => {
        const response = err.response;
        if (response.status === 401) {
          setToken(null);
        }
        else {
          if (response.status === 422) {
            setErrors(response.data.errors);
            setLoading(false);
            toast.error('Une erreur est survenue!', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          } else {
            if (response.status === 403) {
              navigate('/403');
            } else {
              if (response.status === 404) {
                navigate('/404');
              }
              else {
                navigate('/500');
              }
            }
          }

        }
      })
  }

  const cloturer = () => {
    Swal.fire({
      title: "Confirmation",
      text: "Etes vous sûre de cloturer cet devoir?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#2196F3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui!",
      cancelButtonText: "Non!"
    }).then((result) => {
      if (result.isConfirmed) {
        axiosClient.get('/cloturerDevoir/' + selectedDevoir.id)
          .then(response => {
            toast.success('Opération effectuée avec succès!', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            //getDevoir();
          }).catch(err => {
            const response = err.response;
            if (err.response.status === 401) {
              setToken(null);
            } else {
              if (response.status === 422) {
                setErrors(response.data.errors);
              }
              else {
                if (response.status === 403) {
                  navigate('/403');
                } else {
                  if (response.status === 404) {
                    navigate('/404');
                  }
                  else {
                    navigate('/500');
                  }
                }
              }
            }

          })
      }
    });
  }

  const closeEdit=()=>{
    setShowEditNote(false);
    setSelectedNote(null);
    getDevoir();
  }



  useEffect(() => {
    getDevoir();
    getEleves();
  }, []);
  return (
    <>
      <div className=' fixed inset-0 h-full bg-black bg-opacity-25 backdrop-blur-sm  '>
        <div className='h-screen flex justify-center items-center'>
          <div className='flex-col max-h-screen  overflow-y-auto bg-white py-12 px-7 w-auto md:w-1/2 lg:w-1/2 xl:w-1/2 rounded-xl'>
            <Header icon={<PiNotePencilBold />} category='Page' title={'Devoirs ' + selectedDevoir.classe.libelle + ' ' + selectedDevoir.matiereLibelle +' / '+selectedDevoir.trimestre.code}  />
            {errors &&
              Object.keys(errors).map(key => (
                <Alert key={key} message={errors[key][0]} />
              ))
            }
            <div className='flex-col '>
            <div className="flex gap-3 justify-end">
                <div className="flex gap-3 text-4xl p-3  border bg-green-300 border-green-400 text-white rounded">
                  <div className="flex ">
                    <RiEmotionHappyFill />
                  </div>
                  <span>
                    {devoir && devoir.reussit} %
                  </span>
                </div>
                <div className="flex gap-3 text-4xl p-3  border bg-red-300 border-red-400 text-white rounded">
                  <div className="flex">
                    <RiEmotionUnhappyFill />
                  </div>
                  <span>
                    {devoir && devoir.blame} %
                  </span>
                </div>
              </div>
              <div className='flex flex-wrap md:flex-nowrap lg:flex-nowrap xl:flex-nowrap justify-between'>
                <div className="flex w-full flex-wrap md:flex-nowrap lg:flex-nowrap xl:flex-nowrap gap-2">
                  <div className="flex-col w-full">
                    <label className="block text-sm font-medium leading-6 text-gray-900">Elève <span className='text-red-500'>*</span></label>
                    <Select className='mt-2 outline-1 ' placeholder="Elève..." cacheOptions defaultOptions value={selectedEleve} getOptionLabel={e => e.eleve+" "+e.matricule} getOptionValue={e => e.id} options={eleves} onInputChange={handleInputChangeEleve} onChange={handleChangeEleve} />
                  </div>
                  <Input label="Note" type="number" value={req.note} onChange={ev => setReq({ ...req, note: ev.target.value })} required="required" />
                  
                  <div className='mt-8'>
                    <Button onClick={saveNote} color="bg-green-500" icon={<FaFeatherPointed />} size="20" text="Noter" textColor="text-white" />
                  </div>
                  {user.role && (user.role.name === 'dretude' ||user.role.name === 'admin') ?
                    <div className='mt-8'>
                      <Button onClick={() => cloturer()} color="bg-blue-500" icon={<FaCheckCircle />} size="20" text="Cloturer" textColor="text-white" />
                    </div>
                    :
                    <></>

                  }
                  <div className='mt-8'>
                    <Button onClick={() => onClose()} color="bg-red-500" icon={<GiCancel />} size="20" text="Fermer" textColor="text-white" />
                  </div>

                </div>
              </div>

              <div className='mt-3 w-full overflow-x-auto'>
                <table ref={tableRef} className='table-auto'>
                  <thead>
                    <tr>
                      <th>Matricule</th>
                      <th>Eleve</th>
                      <th>Note</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  {loading ? <tbody><tr className='content-center items-center'><td className='content-center items-center' colSpan="9"><Loading /></td></tr></tbody> :
                    (

                      <tbody>
                        {devoir && devoir.notes.map(o => (
                          <tr key={o.id}>
                            <td>{o.matricule}</td>
                            <td>{o.eleve}</td>
                            <td>{o.note}</td>
                            <td>
                              <div className="flex justify-center w-full items-center gap-2">
                                <ActionButton onClick={() => {
                                  setSelectedNote(o);
                                  setShowEditNote(true);
                                }} color="bg-blue-500" icon={<FaEdit />} textColor="text-white" title="Modifier" />
                                <ActionButton onClick={() => supprimer(o)} color="bg-red-500" icon={<BsTrash />} textColor="text-white" title="Supprimer" />
                              </div>
                            </td>
                          </tr>
                        ))

                        }
                      </tbody>
                    )
                  }
                </table>
              </div>
              
            </div>
          </div>
        </div>
        {
          showEditNote ? <EditNoteDevoir selectedNote={selectedNote} onClose={closeEdit}/> : <></>
        }

      </div>
    </>
  )
}

export default VoirDevoir