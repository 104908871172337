import React, { useEffect, useRef, useState } from 'react'
import Select from 'react-select';
import axiosClient from '../../axios-client';
import Input from '../../components/Input';
import { Button, Header } from '../../components';
import Alert from '../../components/Alert';
import { useStateContext } from '../../contexts/ContextProvider';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import Loading from '../../components/Loading';
import { BiSave } from 'react-icons/bi';
import { FiUsers } from 'react-icons/fi';

const EditUser = () => {
  const { id } = useParams();
  const [items, setItems] = useState([]);
  const [inputValue, setValue] = useState('');
  const [selectedValue, setSelectedValue] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingInfo, setLoadingInfo] = useState(false);
  const [errors, setErrors] = useState(null);
  const { token, setToken } = useStateContext();

  const [user, setUser] = useState({
    id: '',
    username: '',
    nom: '',
    password: '',
    password_confirmation: '',
    agence: ''
  });
  const navigate = useNavigate();
  // handle input change event
  const handleInputChange = value => {
    setValue(value);
  };

  // handle selection
  const handleChange = value => {
    setSelectedValue(value);
    setUser({ ...user, agence: value })

  }

  useEffect(() => {
    getUserInfo();
    //getAgences();
  }, [])
  const getUserInfo = () => {
    setLoadingInfo(true);
    axiosClient.get('/getUser/' + id)
      .then(response => {
        setUser(response.data.user);
        setSelectedValue(response.data.user.agence);
        //setUser({ ...user, agence: response.data.user.agence.id })
        setLoadingInfo(false);
      }).catch(err => {
        const response = err.response;
        if (response.status === 401) {
          setToken(null);
        }
        else {
          if (response.status === 422) {
            setErrors(response.data.errors);
            setLoading(false);
            toast.error('Une erreur est survenue!', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          } else {
            if (response.status === 403) {
              navigate('/403');
            } else {
              if (response.status === 404) {
                navigate('/404');
              }
              else {
                navigate('/500');
              }
            }
          }

        }
      })
  }
  const getAgences = () => {
    axiosClient.get('/agences')
      .then((data) => {

        setItems(data.data.agences);

      })
  }

  const onSubmit = (ev) => {
    ev.preventDefault();
    setErrors(null);
    setLoading(true);
    axiosClient.post('/user/update', user)
      .then((response) => {
        setLoading(true);
        navigate('/users')
      }).catch(err => {
        const response = err.response;
        if (response.status === 401) {
          setToken(null);
        }
        else {
          if (response.status === 422) {
            setErrors(response.data.errors);
            setLoading(false);
            toast.error('Une erreur est survenue!', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          } else {
            if (response.status === 403) {
              navigate('/403');
            } else {
              if (response.status === 404) {
                navigate('/404');
              }
              else {
                navigate('/500');
              }
            }
          }

        }
      })
  }
  return (
    <>
      {loadingInfo ? <Loading /> :
        <div>
          <Header icon={<FiUsers />} category='Page' title='User' />
          {errors &&
            Object.keys(errors).map(key => (
              <Alert key={key} message={errors[key][0]} />
            ))
          }
          <form onSubmit={onSubmit} >
            <Input label="Username" type="text" value={user.username} onChange={ev => setUser({ ...user, username: ev.target.value })} required="required" />
            <Input label="Nom" type="text" value={user.nom} onChange={ev => setUser({ ...user, nom: ev.target.value })} required="required" />
            <Input label="Mots de passe" type="password" value={user.password} onChange={ev => setUser({ ...user, password: ev.target.value })}  />
            <Input label="Confirmater le mots de passe" type="password" value={user.password_confirmation} onChange={ev => setUser({ ...user, password_confirmation: ev.target.value })}  />
            {/* <Select className='mt-4 outline-1 ' placeholder="Selectionner une agence" cacheOptions defaultOptions value={selectedValue} getOptionLabel={e => e.libelle} getOptionValue={e => e.id} options={items} onInputChange={handleInputChange} onChange={handleChange} /> */}
            <div className="mt-3">
              {loading ? <Loading /> :
                <Button color="bg-blue-500" icon={<BiSave />} size="20" text="Enregistrer" textColor="text-white" />
              }
            </div>
          </form>
        </div>

      }
    </>
  )
}

export default EditUser