import React from 'react'

const Header = ({ category, title, icon }) => {
  return (
    <div>
      <div className="mb-10 mt-3 ">
        <p className="text-gray-400">

        </p>
        <div className="flex gap-2">
          <div className=" flex w-auto h-auto text-3xl font-bold tracking-tight text-slate-900 ">
            {icon}
          </div>
          <p className="text-xl font-bold tracking-tight text-slate-900">
            {title}
          </p>
        </div>
      </div>
    </div>
  )
}

export default Header
