import React, { useState } from 'react';
import { Button, Header, Input } from '../../components';
import Loading from '../../components/Loading';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStateContext } from '../../contexts/ContextProvider';
import axiosClient from '../../axios-client';
import Alert from '../../components/Alert';
import { BsCalendar2Range } from 'react-icons/bs';
import { FaCalendarAlt, FaExclamation } from 'react-icons/fa';
import { LuMousePointerClick } from 'react-icons/lu';
import { TbNumber20Small } from 'react-icons/tb';
import { GiCancel } from 'react-icons/gi';
import EleveEmploie from './EleveEmploie';
import EleveNote from './EleveNotes';
import EleveAbsence from './EleveAbsences';
import EleveObservation from './EleveObservations';

const StudentMenu = ({ student, onClose }) => {
    const [loading, setLoading] = useState(false);

    const { token, setToken } = useStateContext();
    const [errors, setErrors] = useState(null);
    const [showEmploie,setShowEmploie]=useState(false);
    const [showNote,setShowNote]=useState(false);
    const [showAbsences,setShowAbsence]=useState(false);
    const [showObservations,setShowObservations]=useState(false);
    const close=()=>{
        setShowEmploie(false);
        setShowNote(false);
        setShowAbsence(false);
        setShowObservations(false);
    }

    return (
        <>
            <div className=' fixed inset-0 h-full bg-black bg-opacity-25 backdrop-blur-sm  '>
                <div className='h-screen flex justify-center items-center'>
                    <div className='flex-col  max-h-screen overflow-y-auto bg-white lg:p-10 xl:p-10  p-2 md:p-10 w-auto md:w-1/2 lg:w-1/2 xl:w-1/2 rounded-xl'>
                        <div className=''>
                            <Header icon={<BsCalendar2Range />} title={student.eleve} />
                        </div>
                        {errors &&
                            Object.keys(errors).map(key => (
                                <Alert key={key} message={errors[key][0]} />
                            ))
                        }


                        <div className='flex-col gap-3 mb-3'>
                            <div className="flex gap-2 bg-green-100  my-2 border border-green-400 text-green-700 px-4 py-3 rounded relative " onClick={()=>{
                                setShowEmploie(true);
                            }}>
                                <div className=" text-3xl">
                                    <FaCalendarAlt />
                                </div>
                                <div className="text-3xl">
                                    Emploie du temps
                                </div>
                            </div>
                            <div className="flex gap-2 bg-blue-100  my-2 border border-blue-400 text-blue-700 px-4 py-3 rounded relative " onClick={()=>{
                                setShowNote(true);
                            }}>
                                <div className=" text-4xl">
                                    <TbNumber20Small />
                                </div>
                                <div className="text-3xl">
                                    Notes
                                </div>
                            </div>
                            <div className="flex gap-2 bg-yellow-100  my-2 border border-yellow-400 text-yellow-700 px-4 py-3 rounded relative "  onClick={()=>{
                                setShowObservations(true);
                            }}>
                                <div className=" text-3xl">
                                    <FaExclamation />
                                </div>
                                <div className="text-3xl">
                                    Onservations
                                </div>
                            </div>
                            <div className="flex gap-2 bg-red-100  my-2 border border-red-400 text-red-700 px-4 py-3 rounded relative " onClick={()=>{
                                setShowAbsence(true);
                            }}>
                                <div className=" text-4xl">
                                    <LuMousePointerClick />
                                </div>
                                <div className="text-3xl">
                                    Absences
                                </div>
                            </div>
                            <div className="flex w-full justify-end">
                                <Button icon={<GiCancel />} onClick={() => onClose()} color="bg-red-500" textColor="text-white" text="Fermer" />
                            </div>
                        </div>
                        {
                            showEmploie ? <EleveEmploie student={student} onClose={close}/> : <></>
                        }
                        {
                            showNote ? <EleveNote student={student} onClose={close}/> : <></>
                        }
                        {
                            showAbsences ? <EleveAbsence student={student} onClose={close}/> : <></>
                        }
                        {
                            showObservations ? <EleveObservation student={student} onClose={close}/> : <></>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default StudentMenu