import React, { useState } from 'react';
import { Button, Header, Input } from '../../components';
import Loading from '../../components/Loading';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStateContext } from '../../contexts/ContextProvider';
import axiosClient from '../../axios-client';
import Alert from '../../components/Alert';
import { toast } from 'react-toastify';
import { GiCancel } from 'react-icons/gi';
import { IoIosSave } from 'react-icons/io';
import Select from 'react-select';
import PrintRecipiet from './PrintRecipiet';
import { LuFileSpreadsheet } from 'react-icons/lu';
import { FaMoneyBills } from 'react-icons/fa6';

const AddPaiement = ({ isVisible = true, onClose, facture, }) => {
    const [loading, setLoading] = useState(false);

    const { token, setToken } = useStateContext();
    const [errors, setErrors] = useState(null);
    const [type, setType] = useState(null);
    const [selectedType, setSelectedType] = useState(null);
    const [inputValueType, setValueType] = useState('');
    const[showPrint,setShowPrint]=useState(false);
    const [selectedPaiement,setSelectedPaiement]=useState(null);
    const [paiement, setPaiement] = useState({
        'montant': 0,
        'facture_id': facture.id,
        'typePaiement': ''
    })

    useEffect(() => {
        getTypes();
    }, []);

    const handleInputChangeType = value => {
        setValueType(value);
    };

    // handle selection


    // handle selection
    const handleChangeType = value => {
        setSelectedType(value);
        setPaiement({ ...paiement, typePaiement: value.libelle });

    }
    const navigate = useNavigate();
    const getTypes = () => {
        axiosClient.get('/typePaiements')
            .then((data) => {
                setType(data.data.types);
            })
    }
    const onSubmit = () => {

        setErrors(null);
        setLoading(true);

        axiosClient.post('/paiement/save', paiement)
            .then((response) => {
                setLoading(false);
                setErrors(null);
                toast.success('Opération effectuée avec succès!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                setSelectedPaiement(response.data.paiement);
                setShowPrint(true);
                //onClose();
            }).catch(err => {
                const response = err.response;
                if (response.status === 401) {
                    setToken(null);
                }
                else {
                    if (response.status === 422) {
                        setErrors(response.data.errors);
                        setLoading(false);
                        toast.error('Merci de verifier les informations a saisies!', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    } else {
                        if (response.status === 403) {
                            navigate('/403');
                        } else {
                            if (response.status === 404) {
                                navigate('/404');
                            }
                            else {
                                navigate('/500');
                            }
                        }
                    }

                }
            })
    }
    const closePrint=()=>{
        setShowPrint(false);
        setSelectedPaiement(null);
        onClose();
    }
    if (!isVisible) {
        return null
    }

    return (
        <>
            <div className=' fixed inset-0 h-full bg-black bg-opacity-25 backdrop-blur-sm  '>
                <div className='h-screen flex justify-center items-center'>
                    <div className='flex-col bg-white lg:p-10 xl:p-10  p-2 md:p-10 w-auto rounded-xl'>
                        <div className=''>
                            <Header icon={<FaMoneyBills />} title="Paiement Facture" />
                        </div>
                        {errors &&
                            Object.keys(errors).map(key => (
                                <Alert key={key} message={errors[key][0]} />
                            ))
                        }
                        
                            <>
                                <div className='flex flex-wrap md:flex-nowrap lg:flex-nowrap xl:flex-nowrap gap-3'>
                                    <div className='flex-col w-full justify-evenly '>
                                        <div className='mr-2'>
                                            <Input label="Total" readOnly="readOnly" type="number" value={facture.total} required="required" />
                                        </div>
                                        <div className='mr-2'>
                                            <Input label="Dispense" readOnly="readOnly" type="number" value={facture.dispense} required="required" />
                                        </div>
                                        <div className='mr-2'>
                                            <Input label="Déja payé" readOnly="readOnly" type="number" value={facture.payee} required="required" />
                                        </div>
                                        <div className='mr-2'>
                                            <Input label="Reste" readOnly="readOnly" type="number" value={facture.total - facture.dispense - facture.payee} required="required" />
                                        </div>
                                        
                                    </div>
                                    <div className='flex-col w-full justify-evenly '>
                                         
                                        <div className='mr-2'>
                                            <Input label="Montant" type="number" value={paiement.montant} onChange={ev => setPaiement({ ...paiement, montant: ev.target.value })} required="required" />
                                        </div>
                                        <div className="flex-col w-full">
                                            <label className="block text-sm font-medium leading-6 text-gray-900">M.paiement <span className='text-red-500'> *</span></label>
                                            <Select className='mt-2 outline-1 ' placeholder="M.Paiement" cacheOptions defaultOptions value={selectedType} getOptionLabel={e => e.libelle} getOptionValue={e => e.id} options={type} onInputChange={handleInputChangeType} onChange={handleChangeType} />
                                        </div>
                                    </div>
                                </div>

                                
                            </>
                        
                        {
                            showPrint ? <PrintRecipiet paiement={selectedPaiement} onClose={closePrint} facture={facture}/> :<></>
                        }
                        <div className='flex  justify-end items-end'>
                            <div className=' flex gap-3 m-2  justify-between'>
                                {loading ? <Loading /> :
                                    <Button icon={<IoIosSave />} onClick={onSubmit} color="ml-1 bg-green-500" textColor="text-white" text="Enregistrer" />

                                }
                                <Button icon={<GiCancel />} onClick={() => onClose()} color="bg-red-500" textColor="text-white" text="Fermer" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddPaiement