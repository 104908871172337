import React from 'react'
import { MultiSelect } from 'react-multi-select-component'

const SelectMulti = ({options,selected,setSelected,label,required}) => {
  return (
    <div className='flex-col w-full' >
        <label className="block text-sm font-medium leading-6  text-gray-900">{label}{required && <span className='text-red-500'> *</span>}</label>
        <MultiSelect className='mt-2 '
        options={options}
        value={selected}
        onChange={setSelected}
        labelledBy="Choisir"
        />
    </div>
  )
}

export default SelectMulti