import React,{useEffect} from 'react';
import './App.css';
import { BrowserRouter,Routes,Route } from 'react-router-dom';


import { Ecommerce, Orders, Calendar, Employees,  Customers, Kanban, Line, Area, Bar, Pie,  ColorPicker,  Editor } from './pages';


import Login from './pages/Login';
function App() {
  
  return (
    <div > 
      
    </div>
  );
}

export default App;


