import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import App from './App';
import { ContextProvider } from './contexts/ContextProvider';
import { RouterProvider } from 'react-router-dom';
import router from './router';

ReactDOM.render(
  <ContextProvider>
    <RouterProvider router={router} />
  </ContextProvider>,
  document.getElementById('root'),
);
