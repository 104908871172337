import React from 'react'

import studentlogo from '../data/studentlogo.png';
const StudentComponent = ({eleve}) => {
    return (
        <div className="flex gap-2 shadow-xl rounded pl-3 py-3">
            <div className="flex w-16 h-16">
                <img src={studentlogo} alt="" />
            </div>
            <div className="flex-col gap-1 ">
                <h1 className='font-bold text-lg'>{eleve.eleve}</h1>
                <h2>Classe: {eleve.classe.libelle}</h2>
                <h1>Matricule: {eleve.matricule}</h1>
            </div>

        </div>
    )
}

export default StudentComponent