import React from 'react'
import { Bs7Circle, BsCalendar2Range, BsFillBuildingFill } from 'react-icons/bs'
import { FaExclamation, FaRegFileAlt, FaRegMoneyBillAlt } from 'react-icons/fa'
import { FiShoppingCart, FiUsers } from 'react-icons/fi'
import { NavLink } from 'react-router-dom'
import { TbMath } from "react-icons/tb";
import { useStateContext } from '../../contexts/ContextProvider'
import { LuFileSpreadsheet, LuMousePointerClick } from 'react-icons/lu'
import { PiNotePencilBold, PiStudentBold } from 'react-icons/pi'
import { FaRegCalendarDays } from 'react-icons/fa6'
import { BiArrowToTop } from "react-icons/bi";
import { MdEditNote, MdManageHistory } from "react-icons/md";
import { CiInboxOut } from "react-icons/ci";

const AdminRole = () => {
  const activeLink = 'flex items-center gap-5 pl-4 pt-3  bp-3 rounded-lg text-white bg-blue-600 text-md m-2';
  const normalLink = 'flex items-center gap-5 pl-4 pt-3  bp-3 rounded-lg  text-md text-gray-700 dark:text-gray-200 dark:hover:text-black hover:bg-light-gray m-2';
  const { setActiveMenu, screenSize, currentColor } = useStateContext();


  const handleCloseSidebar = () => {
    if (screenSize <= 900) {
      setActiveMenu(false);

    }
  }
    return (
        < >

            <div>
                <NavLink to={`/users`} key='users' onClick={handleCloseSidebar}
                    className={({ isActive }) =>
                        isActive ? activeLink : normalLink
                    } style={({ isActive }) => isActive ? { backgroundColor: `${currentColor}` } : { backgroundColor: 'white' }}>
                    <FiUsers />
                    <span className='capitalize mb-2'>
                        Utilisateurs
                    </span>
                </NavLink>
                <NavLink to={`/classes`} key='classes' onClick={handleCloseSidebar}
                    className={({ isActive }) =>
                        isActive ? activeLink : normalLink
                    } style={({ isActive }) => isActive ? { backgroundColor: `${currentColor}` } : { backgroundColor: 'white' }}>
                    <BsFillBuildingFill />
                    <span className='capitalize mb-2'>
                        Classes
                    </span>
                </NavLink>
                <NavLink to={`/inscriptions`} key='inscriptions' onClick={handleCloseSidebar}
                    className={({ isActive }) =>
                        isActive ? activeLink : normalLink
                    } style={({ isActive }) => isActive ? { backgroundColor: `${currentColor}` } : { backgroundColor: 'white' }}>
                    <PiStudentBold />
                    <span className='capitalize mb-2'>
                        Inscriptions
                    </span>
                </NavLink>
                <NavLink to={`/absences`} key='absences' onClick={handleCloseSidebar}
                    className={({ isActive }) =>
                        isActive ? activeLink : normalLink
                    } style={({ isActive }) => isActive ? { backgroundColor: `${currentColor}` } : { backgroundColor: 'white' }}>
                    <LuMousePointerClick />
                    <span className='capitalize mb-2'>
                        Absences
                    </span>
                </NavLink>
                <NavLink to={`/observations`} key='observations' onClick={handleCloseSidebar}
                    className={({ isActive }) =>
                        isActive ? activeLink : normalLink
                    } style={({ isActive }) => isActive ? { backgroundColor: `${currentColor}` } : { backgroundColor: 'white' }}>
                    <FaExclamation />
                    <span className='capitalize mb-2'>
                        Observations
                    </span>
                </NavLink>
                <NavLink to={`/factures`} key='factures' onClick={handleCloseSidebar}
                    className={({ isActive }) =>
                        isActive ? activeLink : normalLink
                    } style={({ isActive }) => isActive ? { backgroundColor: `${currentColor}` } : { backgroundColor: 'white' }}>
                    <LuFileSpreadsheet />
                    <span className='capitalize mb-2'>
                        Factures
                    </span>
                </NavLink>
                <NavLink to={`/depenses`} key='despenses' onClick={handleCloseSidebar}
                    className={({ isActive }) =>
                        isActive ? activeLink : normalLink
                    } style={({ isActive }) => isActive ? { backgroundColor: `${currentColor}` } : { backgroundColor: 'white' }}>
                    <FiShoppingCart />
                    <span className='capitalize mb-2'>
                        Depenses
                    </span>
                </NavLink>
                <NavLink to={`/alimentations`} key='alimentations' onClick={handleCloseSidebar}
                    className={({ isActive }) =>
                        isActive ? activeLink : normalLink
                    } style={({ isActive }) => isActive ? { backgroundColor: `${currentColor}` } : { backgroundColor: 'white' }}>
                    <BiArrowToTop />
                    <span className='capitalize mb-2'>
                        Alimentations
                    </span>
                </NavLink>
                <NavLink to={`/sorties`} key='sorties' onClick={handleCloseSidebar}
                    className={({ isActive }) =>
                        isActive ? activeLink : normalLink
                    } style={({ isActive }) => isActive ? { backgroundColor: `${currentColor}` } : { backgroundColor: 'white' }}>
                    <CiInboxOut />
                    <span className='capitalize mb-2'>
                        Sorties
                    </span>
                </NavLink>
                <NavLink to={`/situations`} key='situations' onClick={handleCloseSidebar}
                    className={({ isActive }) =>
                        isActive ? activeLink : normalLink
                    } style={({ isActive }) => isActive ? { backgroundColor: `${currentColor}` } : { backgroundColor: 'white' }}>
                    <FaRegCalendarDays />
                    <span className='capitalize mb-2'>
                        Situations
                    </span>
                </NavLink>
                
                <NavLink to={`/examens`} key='examens' onClick={handleCloseSidebar}
                    className={({ isActive }) =>
                        isActive ? activeLink : normalLink
                    } style={({ isActive }) => isActive ? { backgroundColor: `${currentColor}` } : { backgroundColor: 'white' }}>
                    <MdEditNote />
                    <span className='capitalize mb-2'>
                        Examens
                    </span>
                </NavLink>
                <NavLink to={`/devoirs`} key='devoirs' onClick={handleCloseSidebar}
                    className={({ isActive }) =>
                        isActive ? activeLink : normalLink
                    } style={({ isActive }) => isActive ? { backgroundColor: `${currentColor}` } : { backgroundColor: 'white' }}>
                    <PiNotePencilBold />
                    <span className='capitalize mb-2'>
                        Devoirs
                    </span>
                </NavLink>
                <NavLink to={`/emploies`} key='emploies' onClick={handleCloseSidebar}
                    className={({ isActive }) =>
                        isActive ? activeLink : normalLink
                    } style={({ isActive }) => isActive ? { backgroundColor: `${currentColor}` } : { backgroundColor: 'white' }}>
                    <BsCalendar2Range />
                    <span className='capitalize mb-2'>
                        Emploies
                    </span>
                </NavLink>
                <NavLink to={`/coefficients`} key='coefficients' onClick={handleCloseSidebar}
                    className={({ isActive }) =>
                        isActive ? activeLink : normalLink
                    } style={({ isActive }) => isActive ? { backgroundColor: `${currentColor}` } : { backgroundColor: 'white' }}>
                    <Bs7Circle />
                    <span className='capitalize mb-2'>
                        Coefficients
                    </span>
                </NavLink>
                <NavLink to={`/bulletins`} key='bulletins' onClick={handleCloseSidebar}
                    className={({ isActive }) =>
                        isActive ? activeLink : normalLink
                    } style={({ isActive }) => isActive ? { backgroundColor: `${currentColor}` } : { backgroundColor: 'white' }}>
                    <FaRegFileAlt />
                    <span className='capitalize mb-2'>
                        Bulletins
                    </span>
                </NavLink>
                
                <NavLink to={`/matieres`} key='matieres' onClick={handleCloseSidebar}
                    className={({ isActive }) =>
                        isActive ? activeLink : normalLink
                    } style={({ isActive }) => isActive ? { backgroundColor: `${currentColor}` } : { backgroundColor: 'white' }}>
                    <TbMath />
                    <span className='capitalize mb-2'>
                        Matieres
                    </span>
                </NavLink>
                <NavLink to={`/historiques`} key='historiques' onClick={handleCloseSidebar}
                    className={({ isActive }) =>
                        isActive ? activeLink : normalLink
                    } style={({ isActive }) => isActive ? { backgroundColor: `${currentColor}` } : { backgroundColor: 'white' }}>
                    <MdManageHistory />
                    <span className='capitalize mb-2'>
                        Historiques
                    </span>
                </NavLink>
                
            </div>
        </>

    )
}

export default AdminRole