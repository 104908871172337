import React, { useEffect, useRef, useState } from 'react'
import { Button, Header, Input } from '../../components'
import Alert from '../../components/Alert'
import { IoIosSearch } from 'react-icons/io';
import Select from 'react-select';
import { PiFilesBold } from 'react-icons/pi';
import { useNavigate } from 'react-router-dom';
import axiosClient from '../../axios-client';
import { useStateContext } from '../../contexts/ContextProvider';
import { toast } from 'react-toastify';
import DropDownButton from '../../components/DropDownButton';
import Loading from '../../components/Loading';
import Historiques from './Historiques';
import AddPaiement from './AddPaiement';
import Dispense from './Dispense';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { FaRegFileExcel } from 'react-icons/fa6';
import { LuFileSpreadsheet } from 'react-icons/lu';

const Factures = () => {
  const [errors, setErrors] = useState(null);
  const [items, setItems] = useState([]);
  const [inputValue, setValue] = useState('');
  const [selectedValue, setSelectedValue] = useState(null);
  const [loading, setLoading] = useState(false);
  const [factures, setFactures] = useState([]);
  const [showHistoriques, setShowHistoriques] = useState(false);
  const [showAddPaiement, setShowAddPaiement] = useState(false);
  const [showDispense, setShowDispense] = useState(false);
  const [selectedFacture, setSelectedFacture] = useState(null);
  const { token, setToken, user } = useStateContext();
  const tableRef = useRef();
  const [somme, setSomme] = useState({
    totalRest: 0,

  })
  const [req, setReq] = useState({
    'DateDebut': '',
    'DateFin': '',
    'mois': 0,
  });
  const navigate = useNavigate();
  // handle input change event

  const handleInputChange = value => {
    setValue(value);
  };

  // handle selection
  const handleChange = value => {
    setSelectedValue(value);
    setReq({ ...req, mois: value.id })

  }

  useEffect(() => {
    getMois();
  }, [])
  const getMois = () => {
    axiosClient.get('/mois-scolaires')
      .then((data) => {
        setItems(data.data.mois);
      })
  }
  const closeHistorique = () => {
    setSelectedFacture(null);
    setShowHistoriques(false);
    getFacture();
  }
  const closeAddPaiement = () => {
    setShowAddPaiement(false);
    setSelectedFacture(null);

    getFacture();
  }

  const closeDispense = () => {
    setShowDispense(false);
    setSelectedFacture(null);

    getFacture();
  }
  const getFacture = () => {
    setLoading(true);
    axiosClient.get('/getFactures?DateDebut=' + req.DateDebut + '&DateFin=' + req.DateFin,)
      .then(response => {
        setFactures(response.data.factures);
        setLoading(false);
        setErrors(null);
      }).catch(err => {
        const response = err.response;
        if (response.status === 401) {
          setToken(null);
        }
        else {
          if (response.status === 422) {
            setErrors(response.data.errors);
            setLoading(false);
            toast.error('Merci de verifier les informations a saisies!', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          } else {
            if (response.status === 403) {
              navigate('/403');
            } else {
              if (response.status === 404) {
                navigate('/404');
              }
              else {
                navigate('/500');
              }
            }
          }

        }
      })
  }

  const generer = () => {
    axiosClient.post('genererFactures', req)
      .then(response => {
        toast.success('Operation effectuee avec succes!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setErrors(null);
      }).catch(err => {
        const response = err.response;
        if (response.status === 401) {
          setToken(null);
        }
        else {
          if (response.status === 422) {
            setErrors(response.data.errors);
            setLoading(false);
            toast.error('Merci de verifier les informations a saisies!', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          } else {
            if (response.status === 403) {
              navigate('/403');
            } else {
              if (response.status === 404) {
                navigate('/404');
              }
              else {
                navigate('/500');
              }
            }
          }

        }
      })
  }
  return (
    <>
      <Header icon={<LuFileSpreadsheet />} title="Factures" />
      {errors &&
        Object.keys(errors).map(key => (
          <Alert key={key} message={errors[key][0]} />
        ))
      }
      <div className='flex gap-2 w-full flex-wrap md:flex-nowrap lg:flex-nowrap xl:flex-nowrap justify-between items-center'>
        <Input label="Date debut" type="date" value={req.DateDebut} onChange={ev => setReq({ ...req, DateDebut: ev.target.value })} required="required" />
        <Input label="Date fin" type="date" value={req.DateFin} onChange={ev => setReq({ ...req, DateFin: ev.target.value })} required="required" />
        <Input label="Expression" type="text" />
        <div className='mt-8 flex gap-2'>
          <Button onClick={() => getFacture()} color="bg-yellow-500" icon={<IoIosSearch />} size="20" text="Chercher" textColor="text-white" />
          <DownloadTableExcel
            filename="Factures"
            sheet="factures"
            currentTableRef={tableRef.current}
          >
            <Button color="bg-green-500" icon={<FaRegFileExcel />} size="20" text="Exporter" textColor="text-white" />

          </DownloadTableExcel>
        </div>
      </div>

      <div className='flex gap-2 w-full flex-wrap md:flex-nowrap lg:flex-nowrap xl:flex-nowrap '>

        <div className='mt-12'>
          {user.role && user.role.name === "caissier" ?
            <>
              <div className='flex flex-col mt-2'>
                <label>Mois Scolaire</label>
                <Select className='mt-4 outline-1 ' placeholder="Selectionner un Mois scolaire" cacheOptions defaultOptions value={selectedValue} getOptionLabel={e => e.libelle} getOptionValue={e => e.id} options={items} onInputChange={handleInputChange} onChange={handleChange} />
              </div>
              <Button onClick={() => generer()} color="bg-blue-500" icon={<PiFilesBold />} size="20" text="Generer" textColor="text-white" />
            </>
            :
            <></>

          }
        </div>
        <div className="mt-12">

        </div>
      </div>
      <div className="flex my-2">
        <h1 className='text-red-500 font-bold'>Total Reste: {factures.reduce((n, { reste }) => n + parseFloat(reste), 0)} MRU</h1>
      </div>
      <div className='flex mt-3 overflow-auto'>
        {loading ? <Loading /> :
          <table ref={tableRef} className='table-auto' >
            <thead>
              <tr>
                <th>Date</th>
                <th>Description</th>
                <th>Elève</th>
                <th>Classe</th>
                <th>Date Insc</th>
                <th>Total</th>
                <th>Dispense</th>
                <th>Payée</th>
                <th>Reste</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {factures && factures.map(o => (
                <tr className='hover:bg-gray-200' key={o.id}>
                  <td>{o.dateFacture}</td>
                  <td>{o.description}</td>
                  <td>{o.inscription.eleve}</td>
                  <td>{o.inscription.classe.libelle}</td>
                  <td>{o.inscription.dateInscription}</td>
                  <td>{o.total}</td>
                  <td>{o.dispense}</td>
                  <td>{o.payee}</td>
                  <td>{o.total - o.dispense - o.payee}</td>
                  <td><DropDownButton actions={[{
                    'libelle': "Payer",
                    'onClick': () => {
                      setSelectedFacture(o);
                      setShowAddPaiement(true);
                    }
                  },
                  {
                    'libelle': "Historiques",
                    'onClick': () => {
                      setSelectedFacture(o);
                      setShowHistoriques(true);
                    }
                  },
                  {
                    'libelle': "Dispense",
                    'onClick': () => {
                      setSelectedFacture(o);
                      setShowDispense(true);
                    }
                  },
                  ]} />
                  </td>
                </tr>
              ))

              }
            </tbody>
          </table>

        }
        {
          showHistoriques ? <Historiques onClose={closeHistorique} facture={selectedFacture} /> : <></>
        }
        {
          showAddPaiement ? <AddPaiement onClose={closeAddPaiement} facture={selectedFacture} /> : <></>
        }
        {
          showDispense ? <Dispense onClose={closeDispense} facture={selectedFacture} /> : <></>
        }
      </div>

    </>
  )
}

export default Factures