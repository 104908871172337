import React from 'react'
import icon from '../data/icon.png';
import { useStateContext } from '../contexts/ContextProvider';
import MesEleves from './parents/MesEleves';

const Home = () => {
  const { setToken, user } = useStateContext();
  return (
    <div>
      {user.role && user.role.name === 'parent' ?
        <MesEleves />
        :
        <div className='flex h-screen p-48 justify-center items-center '>
          <div className='animate-pulse'>
            <img className="mx-auto h-auto w-auto" src={icon} alt="Your Company" />
          </div>
        </div>
      }
    </div>
  )
}
export default Home