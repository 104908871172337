import React from 'react'
import { ThreeDots } from 'react-loader-spinner'
import { useStateContext } from '../contexts/ContextProvider'

const Loading = (width) => {
    const { currentColor } = useStateContext();
    return (
        <div className='w-full flex justify-center'>
            <div className='w-1/2  flex justify-center'>
                < ThreeDots
                    height="60"
                    width="60"
                    radius="9"
                    ariaLabel="loading"
                    wrapperStyle
                    wrapperClass color={currentColor} />
            </div>

        </div>
    )
}



export default Loading