import React, { useState } from 'react';
import { Button, Header, Input } from '../../components';
import Loading from '../../components/Loading';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStateContext } from '../../contexts/ContextProvider';
import axiosClient from '../../axios-client';
import Alert from '../../components/Alert';
import { toast } from 'react-toastify';
import { GiCancel } from 'react-icons/gi';
import { IoIosSave } from 'react-icons/io';
import Select from 'react-select';
import { BsCalendar2Range } from 'react-icons/bs';

const AddEmploie = ({ isVisible = true, onClose }) => {
    const [loading, setLoading] = useState(false);

    const { token, setToken } = useStateContext();
    const [errors, setErrors] = useState(null);
    const [classes, setClasses] = useState([]);
    const [selectedClasse, setSelectedClasse] = useState(null);
    const [inputValueClasse, setValueClasse] = useState('');

    const [matieres, setMatieres] = useState([]);
    const [selectedMatiere, setSelectedMatiere] = useState(null);
    const [inputValueMatiere, setValueMatiere] = useState('');

    const [jours, setJours] = useState([]);
    const [selectedJour, setSelectedJour] = useState(null);
    const [inputValueJour, setValueJour] = useState('');

    const [enseignants, setEnseignant] = useState([]);
    const [selectedEnseignant, setSelectedEnseignant] = useState(null);
    const [inputValueEnseignant, setValueEnseignant] = useState('');


    const [emploie, setEmploie] = useState({
        classe: '',
        matiere: '',
        heureDebut:null,
        heureFin:null,
        enseignant:null,
        jour:null

    });

    const handleInputChangeClasse = value => {
        setValueClasse(value);
    };

    // handle selection


    // handle selection
    const handleChangeMatiere = value => {
        setSelectedMatiere(value);
        setEmploie({ ...emploie, matiere: value.id });

    }

    const handleInputChangeMatiere = value => {
        setValueMatiere(value);
    };

    // handle selection

    const handleChangeJour = value => {
        setSelectedJour(value);
        setEmploie({ ...emploie, jour: value.id });


    }

    const handleInputChangeJour = value => {
        setValueJour(value);
    };


    const handleChangeEnseignant = value => {
        setSelectedEnseignant(value);
        setEmploie({ ...emploie, enseignant: value.id });


    }

    const handleInputChangeEnseignant = value => {
        setValueEnseignant(value);
    };
    // handle selection
    const handleChangeClasse = value => {
        setSelectedClasse(value);
        setEmploie({ ...emploie, classe: value.id });
        setLoading(true);
        getMatieres(value.groupe_id);
        setLoading(false);

    }


    const navigate = useNavigate();
    const getMatieres = (groupe_id) => {
        axiosClient.get('/classe/matieres/' + groupe_id)
            .then((data) => {
                setMatieres(data.data.matieres);
            }).catch(err => {
                navigate('/500');
            })
    }

    const getClasses = () => {
        axiosClient.get('/classes')
            .then((data) => {
                setClasses(data.data.classes);
            }).catch(err => {
                navigate('/500');
            })
    }

    const getEnseignants = () => {
        axiosClient.get('/enseignants')
            .then((data) => {
                setEnseignant(data.data.enseignants);
            }).catch(err => {
                navigate('/500');
            })
    }

    const getJours = () => {
        axiosClient.get('/jours')
            .then((data) => {
                setJours(data.data.jours);
            }).catch(err => {
                navigate('/500');
            })
    }

    useEffect(() => {
        setLoading(true);
        getClasses();
        getJours();
        getEnseignants();
        setLoading(false);
    }, []);
    const onSubmit = () => {

        setErrors(null);
        setLoading(true);

        axiosClient.post('/emploie/save', emploie)
            .then((response) => {
                setLoading(false);
                setErrors(null);
                toast.success('Opération effectuée avec succès!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                onClose();
            }).catch(err => {
                const response = err.response;
                if (response.status === 401) {
                    setToken(null);
                }
                else {
                    if (response.status === 422) {
                        setErrors(response.data.errors);
                        setLoading(false);
                        toast.error('Une erreur est survenue!', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    } else {
                        if (response.status === 403) {
                            navigate('/403');
                        } else {
                            if (response.status === 404) {
                                navigate('/404');
                            }
                            else {
                                navigate('/500');
                            }
                        }
                    }

                }
            })
    }



    return (
        <>
            <div className=' fixed inset-0 h-full bg-black bg-opacity-25 backdrop-blur-sm  '>
                <div className='h-screen flex justify-center items-center'>
                    <div className='flex-col  max-h-screen overflow-y-auto bg-white lg:p-10 xl:p-10  p-2 md:p-10 w-auto md:w-1/2 lg:w-1/2 xl:w-1/2 rounded-xl'>
                        <div className=''>
                            <Header icon={<BsCalendar2Range />} title="Ajouter un cours" />
                        </div>
                        {errors &&
                            Object.keys(errors).map(key => (
                                <Alert key={key} message={errors[key][0]} />
                            ))
                        }
                       
                            <>
                                <div className='flex flex-wrap md:flex-nowrap lg:flex-nowrap xl:flex-nowrap gap-2'>
                                    <div className='flex-col w-full justify-evenly '>
                                        <div className="flex-col w-full">
                                            <label className="block text-sm font-medium leading-6 text-gray-900">Classe <span className='text-red-500'> *</span></label>
                                            <Select className='mt-2 outline-1 ' placeholder="Classe" cacheOptions defaultOptions value={selectedClasse} getOptionLabel={e => e.libelle} getOptionValue={e => e.id} options={classes} onInputChange={handleInputChangeClasse} onChange={handleChangeClasse} />
                                        </div>
                                        <div className="flex-col w-full">
                                            <label className="block text-sm font-medium leading-6 text-gray-900">Matiere <span className='text-red-500'> *</span></label>
                                            <Select className='mt-2 outline-1 ' placeholder="Matiere" cacheOptions defaultOptions value={selectedMatiere} getOptionLabel={e => e.matiere.libelle} getOptionValue={e => e.id} options={matieres} onInputChange={handleInputChangeMatiere} onChange={handleChangeMatiere} />
                                        </div>
                                        <div className="flex-col w-full">
                                            <label className="block text-sm font-medium leading-6 text-gray-900">Enseignant <span className='text-red-500'> *</span></label>
                                            <Select className='mt-2 outline-1 ' placeholder="Enseignant" cacheOptions defaultOptions value={selectedEnseignant} getOptionLabel={e => e.nomTel} getOptionValue={e => e.id} options={enseignants} onInputChange={handleInputChangeEnseignant} onChange={handleChangeEnseignant} />
                                        </div>

                                    </div>
                                    <div className='flex-col w-full justify-evenly '>

                                        <div className="flex-col w-full">
                                            <label className="block text-sm font-medium leading-6 text-gray-900">Jour <span className='text-red-500'> *</span></label>
                                            <Select className='mt-2 outline-1 ' placeholder="Jour" cacheOptions defaultOptions value={selectedJour} getOptionLabel={e => e.libelleFr} getOptionValue={e => e.id} options={jours} onInputChange={handleInputChangeJour} onChange={handleChangeJour} />
                                        </div>
                                        <div className='mr-2'>
                                            <Input label="Heure Debut" type="time" value={emploie.heureDebut} onChange={ev => setEmploie({ ...emploie, heureDebut: ev.target.value })} required="required" />
                                        </div>
                                        <div className='mr-2'>
                                            <Input label="Heure Fin" type="time" value={emploie.heureFin} onChange={ev => setEmploie({ ...emploie, heureFin: ev.target.value })} required="required" />
                                        </div>
                                    </div>
                                </div>
                            </>
                        
                        <div className='flex  justify-end items-end'>
                            <div className=' flex gap-3 m-2  justify-between'>
                                {loading ? <Loading /> :
                                    <Button icon={<IoIosSave />} onClick={onSubmit} color="ml-1 bg-green-500" textColor="text-white" text="Enregistrer" />

                                }
                                <Button icon={<GiCancel />} onClick={() => onClose()} color="bg-red-500" textColor="text-white" text="Fermer" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddEmploie