import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { SlArrowDown } from "react-icons/sl";
const DropDownButton = ({ titre, actions }) => {
  const [isClicked, setIsClicked] = useState(false);
  return (
    <div className='flex relative'>

      <button onClick={() => setIsClicked(!isClicked)}
        className="text-white bg-green-500 hover:bg-green-600 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-3 py-1.5 text-center inline-flex items-center" type="button">
        Actions
        <svg class="w-2.5 h-2.5 ms-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4" />
        </svg>

      </button>


      {isClicked ?
        <>
          <div onMouseLeave={() => setIsClicked(false)} className="z-30  top-11  right-1 absolute bg-white divide-y divide-gray-100 rounded-lg shadow-md shadow-green-200 w-44 dark:bg-gray-700 dark:divide-gray-600">
            <ul className="py-2 text-sm text-gray-700 dark:text-gray-200" >
              {actions.map(o => (
                <>
                <li className='hover:bg-gray-100'>
                  <button onClick={o.onClick} className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">{o.libelle}</button>
                </li>
                <hr />
                </>
              ))

              }

            </ul>

          </div>
        </> : <></>}

    </div>
  )
}

export default DropDownButton