import React, { useEffect, useRef, useState } from 'react'
import { Button, Header, Input } from '../../components'
import { BsPlusCircleFill, BsTrash } from 'react-icons/bs';
import { Link, useNavigate } from 'react-router-dom';
import axiosClient from '../../axios-client';
import Loading from '../../components/Loading';
import { useStateContext } from '../../contexts/ContextProvider';
import { AiTwotoneEdit } from 'react-icons/ai';
import ActionButton from '../../components/ActionButton';
import { MdOutlineAccountBalanceWallet } from "react-icons/md";
import { IoIosSearch } from 'react-icons/io';
import Select from 'react-select';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { FaExclamation, FaRegFileExcel } from 'react-icons/fa';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { PiStudentBold } from 'react-icons/pi';
import AddObservation from '../observations/AddObseravtions';

const Inscriptions = () => {
  const navigate = useNavigate();
  const [inscriptions, setInscriptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const { setToken, user } = useStateContext();
  const [scolaire, setScolaire] = useState([]);
  const [selectedScolaire, setSelectedScolaire] = useState(null);
  const [inputValueScolaire, setValueScolaire] = useState('');

  const [selectedEleve, setSelectedEleve] = useState(null);
  const [showAddObservation, setShowAddObservation] = useState(false);

  const tableRef = useRef(null);
  const [req, setReq] = useState({
    exp: '',
    scol: ''
  });

  const [somme, setSomme] = useState({
    nombre: 0,
    totalRest: 0
  });

  const getScolaire = () => {
    axiosClient.get('/getScolaires')
      .then((data) => {
        setScolaire(data.data.scolaires);
        setSelectedScolaire(data.data.encours);
        setReq({ ...req, scol: data.data.encours.id })
      }).catch(err => {
        const response = err.response;
        if (err.response.status === 401) {
          setToken(null);
        } else {
          if (response.status === 401) {
            setToken(null);
          }
          else {
            if (response.status === 403) {
              navigate('/403');
            } else {
              if (response.status === 404) {
                navigate('/404');
              }
              else {
                navigate('/500');
              }
            }
          }
        }

      })
  }
  const handleInputChangeScolaire = value => {
    setValueScolaire(value);
  };

  // handle selection


  // handle selection
  const handleChangeScolaire = value => {
    setSelectedScolaire(value);
    setReq({ ...req, scolaire: value.id });
  }
  const getInscriptions = () => {
    setLoading(true);
    axiosClient.get('/inscriptions?scolaire=' + req.scol + '&exp=' + req.exp)
      .then((data) => {
        setInscriptions(data.data.inscriptions);
        setLoading(false);

      }).catch(err => {
        const response = err.response;
        if (err.response.status === 401) {
          setToken(null);
        } else {
          if (response.status === 401) {
            setToken(null);
          }
          else {
            if (response.status === 403) {
              navigate('/403');
            } else {
              if (response.status === 404) {
                navigate('/404');
              }
              else {
                navigate('/500');
              }
            }
          }
        }

      })
  }

  const resiler = (inscription) => {
    Swal.fire({
      title: "Confirmation",
      text: "Etes vous sûre de supprimer cette element ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#2196F3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui!",
      cancelButtonText: "Non!"
    }).then((result) => {
      if (result.isConfirmed) {
        axiosClient.delete('/inscription/' + inscription.id)
          .then(response => {
            toast.success('Opération effectuée avec succès!', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            getInscriptions();
          }).catch(err => {
            const response = err.response;
            if (err.response.status === 401) {
              setToken(null);
            } else {
              if (response.status === 401) {
                setToken(null);
              }
              else {
                if (response.status === 403) {
                  navigate('/403');
                } else {
                  if (response.status === 404) {
                    navigate('/404');
                  }
                  else {
                    navigate('/500');
                  }
                }
              }
            }

          })
      }
    });
  }

  const closeAddObservation = () => {
    setShowAddObservation(false);
    setSelectedEleve(null);
  }

  useEffect(() => {
    getScolaire();

  }, []);
  return (
    <>
      <Header icon={<PiStudentBold />} category='Page' title='Inscriptions' />
      <div className='flex-col '>
        <div className='flex flex-wrap md:flex-nowrap lg:flex-nowrap xl:flex-nowrap justify-between'>
          {
            user.role && user.role.name === 'caissier' ?
              <Link to='/inscription/new'>
                <Button color="bg-blue-500" icon={<BsPlusCircleFill />} size="20" text="Ajouter" textColor="text-white" />
              </Link>
              : <></>
          }

          <div className="flex flex-wrap md:flex-nowrap lg:flex-nowrap xl:flex-nowrap gap-2">
            <div className="flex-col w-full">
              <label className="block text-sm font-medium leading-6 text-gray-900">Année Scolaire <span className='text-red-500'> *</span></label>
              <Select className='mt-2 outline-1 ' placeholder="Année Scolaire" cacheOptions defaultOptions value={selectedScolaire} getOptionLabel={e => e.code} getOptionValue={e => e.id} options={scolaire} onInputChange={handleInputChangeScolaire} onChange={handleChangeScolaire} />
            </div>
            <Input label="Expression" type="text" value={req.exp} onChange={ev => setReq({ ...req, exp: ev.target.value })} />
            <div className='mt-8'>
              <Button onClick={() => getInscriptions()} color="bg-yellow-500" icon={<IoIosSearch />} size="20" text="Chercher" textColor="text-white" />
            </div>
            <div className="mt-8">
              <DownloadTableExcel
                filename="Inscriptions"
                sheet="inscriptions"
                currentTableRef={tableRef.current}
              >
                <Button color="bg-green-500" icon={<FaRegFileExcel />} size="20" text="Exporter" textColor="text-white" />

              </DownloadTableExcel>
            </div>
          </div>
        </div>
        <div className="flex flex-col my-2 gap-2 ">
          <h1 className='text-green-700 font-bold'>Nombre total: {inscriptions.length} Elève(s)</h1>
          {
            user.role && user.role.name !== 'dretude' ?
              <>
                <h1 className='text-red-500 font-bold'>Total Reste: {inscriptions.reduce((n, { reste }) => n + parseFloat(reste), 0)} MRU</h1>
              </>
              :
              <></>
          }
        </div>
        <div className='mt-3 w-full overflow-x-auto'>
          <table ref={tableRef} className='table-auto'>
            <thead>
              <tr>
                <th>Date</th>
                <th>Matricule</th>
                <th>Classe</th>
                <th>Elève</th>
                <th>Téléphone</th>
                {user.role && user.role.name !== 'dretude' ?
                  <>
                    <th>Mensualité</th>
                    <th>Reduction</th>
                    <th>Reste</th>
                  </>
                  :
                  <>
                    <th>WhatsApp</th>
                    <th>Autre tel</th>
                    <th>Genre</th>
                  </>
                }
                <th>Actions</th>
              </tr>
            </thead>
            {loading ? <tbody><tr className='content-center items-center'><td className='content-center items-center' colSpan="9"><Loading /></td></tr></tbody> :
              (

                <tbody>
                  {inscriptions && inscriptions.map(o => (
                    <tr className={(o.reste !== 0 ? 'bg-red-200' : '')} key={o.id}>
                      <td>{o.dateInscription}</td>
                      <td>{o.matricule}</td>
                      <td>{o.classe.libelle}</td>
                      <td>{o.eleve}</td>
                      <td>{o.telParent}</td>
                      {
                        user.role && user.role.name !== 'dretude' ?
                          <>
                            <td>{o.montant}</td>
                            <td>{o.reduction}</td>
                            <td>{o.reste}</td>
                          </>
                          :
                          <>
                            <td>{o.whatsapp}</td>
                            <td>{o.autreTel}</td>
                            <td>{o.genre}</td>
                          </>
                      }
                      <td>
                        {
                          user.role && user.role.name !== 'dretude' ?
                            <>
                              <div className="flex justify-between">
                                <Link to={`/inscriptionAccount/${o.id}`}>
                                  <ActionButton color="bg-blue-500" icon={<MdOutlineAccountBalanceWallet />} textColor="text-white" title="Compte Eleve" />
                                </Link>
                                <Link to={`/inscription/${o.id}`}>
                                  <ActionButton color="bg-green-500" icon={<AiTwotoneEdit />} textColor="text-white" title="Modifier" />
                                </Link>
                                <ActionButton onClick={() => { resiler(o) }} color="bg-red-500" icon={<BsTrash />} textColor="text-white" title="Resiler" />
                              </div>
                            </>
                            :
                            <>
                              <div className="flex justify-between">
                                <ActionButton onClick={() => {
                                  setSelectedEleve(o);
                                  setShowAddObservation(true);
                                }} color="bg-yellow-500" icon={<FaExclamation />} textColor="text-white" title="Observation" />
                                <Link to={`/inscription/${o.id}`}>
                                  <ActionButton color="bg-green-500" icon={<AiTwotoneEdit />} textColor="text-white" title="Modifier" />
                                </Link>
                              </div>
                            </>
                        }
                      </td>
                    </tr>
                  ))

                  }
                </tbody>

              )
            }
          </table>
        </div>
        {
          showAddObservation ? <AddObservation eleve={selectedEleve} onClose={closeAddObservation} /> : <></>
        }
      </div>
    </>
  )
}

export default Inscriptions