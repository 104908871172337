import React from 'react'
import { BsFillBuildingFill } from 'react-icons/bs'
import { FaRegMoneyBillAlt } from 'react-icons/fa'
import { FiUsers } from 'react-icons/fi'
import { NavLink } from 'react-router-dom'
import { useStateContext } from '../../contexts/ContextProvider'
import { BiTransferAlt } from 'react-icons/bi'
import { Bs7Circle } from "react-icons/bs";
import { MdEditNote } from "react-icons/md";
import { PiNotePencilBold } from "react-icons/pi";
import { BsCalendar2Range } from "react-icons/bs";
import { FaRegFileAlt } from "react-icons/fa";

const EnseignantRole = () => {
  const activeLink = 'flex items-center gap-5 pl-4 pt-3  bp-3 rounded-lg text-white bg-blue-600 text-md m-2';
  const normalLink = 'flex items-center gap-5 pl-4 pt-3  bp-3 rounded-lg  text-md text-gray-700 dark:text-gray-200 dark:hover:text-black hover:bg-light-gray m-2';
  const { setActiveMenu, screenSize, currentColor } = useStateContext();


  const handleCloseSidebar = () => {
    if (screenSize <= 900) {
      setActiveMenu(false);

    }
  }
    return (
        <div >
            <div>
                <NavLink to={`/monemploie`} key='emploies' onClick={handleCloseSidebar}
                    className={({ isActive }) =>
                        isActive ? activeLink : normalLink
                    } style={({ isActive }) => isActive ? { backgroundColor: `${currentColor}` } : { backgroundColor: 'white' }}>
                    <Bs7Circle />
                    <span className='capitalize mb-2'>
                        Emploie
                    </span>
                </NavLink>
                <NavLink to={`/mesexamens`} key='examens' onClick={handleCloseSidebar}
                    className={({ isActive }) =>
                        isActive ? activeLink : normalLink
                    } style={({ isActive }) => isActive ? { backgroundColor: `${currentColor}` } : { backgroundColor: 'white' }}>
                    <MdEditNote />
                    <span className='capitalize mb-2'>
                        Examens
                    </span>
                </NavLink>
                <NavLink to={`/mesdevoirs`} key='devoirs' onClick={handleCloseSidebar}
                    className={({ isActive }) =>
                        isActive ? activeLink : normalLink
                    } style={({ isActive }) => isActive ? { backgroundColor: `${currentColor}` } : { backgroundColor: 'white' }}>
                    <PiNotePencilBold />
                    <span className='capitalize mb-2'>
                        Devoirs
                    </span>
                </NavLink>
            </div>
        </div>

    )
}

export default EnseignantRole