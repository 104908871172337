import React, { useState } from 'react';
import { Button, Header, Input } from '../../components';
import Loading from '../../components/Loading';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStateContext } from '../../contexts/ContextProvider';
import axiosClient from '../../axios-client';
import Alert from '../../components/Alert';
import { toast } from 'react-toastify';
import { GiCancel } from 'react-icons/gi';
import { IoIosSave } from 'react-icons/io';
import Select from 'react-select';
import { Bs7Circle } from 'react-icons/bs';

const AddCoefficient = ({ isVisible = true, onClose }) => {
    const [loading, setLoading] = useState(false);

    const { token, setToken } = useStateContext();
    const [errors, setErrors] = useState(null);
    const [groupe,setGroupe]=useState([]);
    const [selectedGroupe,setSelectedGroupe]=useState(null);
    const [inputValueGroupe, setValueGroupe] = useState('');

    const [matieres,setMatieres]=useState([]);
    const [selectedMatiere,setSelectedMatiere]=useState(null);
    const [inputValueMatiere, setValueMatiere] = useState('');
  

    const [coefficient, setCoefficient] = useState({
        groupe: '',
        matiere: '',
        coeff: '',
        noteMax: '',
        ordre:'',

    });

    const handleInputChangeGroupe = value => {
        setValueGroupe(value);
      };
    
      // handle selection
    
    
      // handle selection
      const handleChangeMatiere = value => {
        setSelectedMatiere(value);
        setCoefficient({ ...coefficient, matiere:value.id });
        
      }

      const handleInputChangeMatiere = value => {
        setValueMatiere(value);
      };
    
      // handle selection
    
    
      // handle selection
      const handleChangeGroupe = value => {
        setSelectedGroupe(value);
        setCoefficient({ ...coefficient, groupe:value.id });
        
      }


    const navigate = useNavigate();
    const getMatieres = () => {
        axiosClient.get('/matieres')
          .then((data) => {
            setMatieres(data.data.matieres);
          }).catch(err=>{
            navigate('/500');
          })
      }

      const getGroupes = () => {
        axiosClient.get('/groupes')
          .then((data) => {
            setGroupe(data.data.groupes);
          }).catch(err=>{
            navigate('/500');
          })
      }
    useEffect(() => {
        setLoading(true);
        getGroupes();
        getMatieres();
        setLoading(false);
    }, []);
    const onSubmit = () => {

        setErrors(null);
        setLoading(true);

        axiosClient.post('/coefficient/save', coefficient)
            .then((response) => {
                setLoading(false);
                setErrors(null);
                toast.success('Opération effectuée avec succès!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                onClose();
            }).catch(err => {
                const response = err.response;
                if (response.status === 401) {
                    setToken(null);
                }
                else {
                    if (response.status === 422) {
                        setErrors(response.data.errors);
                        setLoading(false);
                        toast.error('Une erreur est survenue!', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    } else {
                        if (response.status === 403) {
                            navigate('/403');
                        } else {
                            if (response.status === 404) {
                                navigate('/404');
                            }
                            else {
                                navigate('/500');
                            }
                        }
                    }

                }
            })
    }

    if (!isVisible) {
        return null
    }

    return (
        <>
            <div className=' fixed inset-0 h-full bg-black bg-opacity-25 backdrop-blur-sm  '>
                <div className='h-screen flex justify-center items-center'>
                    <div className='flex-col bg-white lg:p-10 xl:p-10  p-2 md:p-10 w-auto rounded-xl'>
                        <div className=''>
                            <Header icon={<Bs7Circle />} title="Ajouter un coefficient" />
                        </div>
                        {errors &&
                            Object.keys(errors).map(key => (
                                <Alert key={key} message={errors[key][0]} />
                            ))
                        }
                        
                            <>
                                <div className='flex flex-wrap md:flex-nowrap lg:flex-nowrap xl:flex-nowrap gap-3'>
                                    <div className='flex-col w-full justify-evenly '>
                                        <div className="flex-col w-full">
                                            <label className="block text-sm font-medium leading-6 text-gray-900">Groupe <span className='text-red-500'> *</span></label>
                                            <Select className='mt-2 outline-1 ' placeholder="Groupe" cacheOptions defaultOptions value={selectedGroupe} getOptionLabel={e => e.libelle} getOptionValue={e => e.id} options={groupe} onInputChange={handleInputChangeGroupe} onChange={handleChangeGroupe} />
                                        </div>
                                        <div className="flex-col w-full">
                                            <label className="block text-sm font-medium leading-6 text-gray-900">Matiere <span className='text-red-500'> *</span></label>
                                            <Select className='mt-2 outline-1 ' placeholder="Matiere" cacheOptions defaultOptions value={selectedMatiere} getOptionLabel={e => e.libelle} getOptionValue={e => e.id} options={matieres} onInputChange={handleInputChangeMatiere} onChange={handleChangeMatiere} />
                                        </div>
                                        <div className='mr-2'>
                                            <Input label="Coefficient" type="text" value={coefficient.coeff} onChange={ev => setCoefficient({ ...coefficient, coeff: ev.target.value })} required="required" />
                                        </div>
                                        <div className='mr-2'>
                                            <Input label="Note Max" type="text" value={coefficient.noteMax} onChange={ev => setCoefficient({ ...coefficient, noteMax: ev.target.value })} required="required" />
                                        </div>
                                        <div className='mr-2'>
                                            <Input label="Ordre" type="text" value={coefficient.ordre} onChange={ev => setCoefficient({ ...coefficient, ordre: ev.target.value })} required="required" />
                                        </div>
                                        
                                    </div>
                                </div>
                            </>
                        
                        <div className='flex  justify-end items-end'>
                            <div className=' flex gap-3 m-2  justify-between'>
                                {loading ? <Loading /> :
                                    <Button icon={<IoIosSave />} onClick={onSubmit} color="ml-1 bg-green-500" textColor="text-white" text="Enregistrer" />

                                }
                                <Button icon={<GiCancel />} onClick={() => onClose()} color="bg-red-500" textColor="text-white" text="Fermer" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddCoefficient