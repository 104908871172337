import React, { useEffect } from 'react'
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { FiSettings } from 'react-icons/fi';
import { useStateContext } from '../contexts/ContextProvider';
import { Navbar, Notification, Sidebar, ThemeSettings } from '../components';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axiosClient from '../axios-client';

const DefaultLayout = () => {
  const getUser = () => {
    axiosClient.get('/user')
      .then((response) => {

        setUser(response.data.user);
      }).catch(error => {
        setToken(null);
        localStorage.removeItem('ACCESS_TOKEN');
        navigate('/login');

      })
  }
  useEffect(() => {
    getUser();
  }, []);
  const { activeMenu, themeSettings, setThemeSettings,
    currentColor, token, setUser, setToken } = useStateContext();

  const navigate = useNavigate();


  if (!token) {
    return <Navigate to="/login" />
  }




  return (
    <div className='flex relative dark:bg-main-dark-bg  animated fadeInDown'>
      {/* <div className='fixed right-4 bottom-4' style={{ zIndex: '1000' }}>
        <TooltipComponent content="Settings" position='top'>
          <button type='button' className='text-3xl p-3 hover:drop-shadow-xl 
              hover:bg-light-gray text-white'
            onClick={() => setThemeSettings(true)}
            style={{
              background: `${currentColor}`,
              borderRadius: '50%'
            }}>
            <FiSettings />
          </button>
        </TooltipComponent>
      </div> */}
      {/* Debut sidebar */}
      {activeMenu ? (
        <div className='w-72 fixed sidebar
            dark:bg-secondery-dark-bg bg-white h-full '>
          <Sidebar />
        </div>
      ) : (
        <div className='w-0 fixed sidebar
            dark:bg-secondery-dark-bg bg-white'>

        </div>
      )}
      {/* Fin sidebar */}

      {/* Debut navbar */}
      <div className={
        `dark:bg-main-bg bg-main-bg min-h-screen 
             w-full ${activeMenu ? 'md:ml-72' : 'flex-2'}`
      }>
        <div className='fixed md:static bg-main-bg dark:bg-main-dark-bg
            navbar w-full'>
          <Navbar />
        </div>
        <div>
          {themeSettings && <ThemeSettings />}
        </div>
        {/* start content */}
        <div className='m-2 mt-12 max-h-screen h-auto overflow-auto lg:mt-3 xl:mt-3 md:m-10 md:mt-12 p-4 md:p-10 bg-white shadow-lg rounded-xl'>
          <Notification />
          <Outlet />
          <ToastContainer />
        </div>

        {/* end content */}

      </div>

    </div>
  )
}

export default DefaultLayout