import React, { useState } from 'react'
import { MdOutlineCancel } from 'react-icons/md'
import { useStateContext } from '../contexts/ContextProvider';
import ChangePassword from '../pages/User/ChangePassword';

const UserProfile = () => {
  const {handleClick,setToken}=useStateContext();
  const [showChangePassword,setShowChangePassword]=useState(false);

  const closeShowChangePassword=()=>{
    setShowChangePassword(false);
  }
  const logout=()=>{
    handleClick('');
    localStorage.removeItem('ACCESS_TOKEN');
    setToken(null);
  }
  return (
    <div onMouseLeave={()=>handleClick('')} className='w-auto flex flex-col justify-evenly absolute cursor-pointer  top-11  right-7 rounded-lg bg-white shadow-md '>
      <div className="flex justify-end">
        <button type='button' onClick={()=>handleClick('')}  style={{ color:'rgb(153,171,180)',borderRadius:'50%' }} className='text-xs p-2  hover:drop-shadow-xl hover:bg-light-gray'>
              <MdOutlineCancel/>
        </button>
      </div>
      <div className="flex  mx-3 my-1 px-2 py-2 rounded-md  hover:bg-blue-200 text-xs">
        <span className='text-gray-700 ' onClick={()=>{
          setShowChangePassword(true);
        }}>Mot de passe</span>
      </div>
      <div className="flex  mx-3 my-1 px-2 py-2 rounded-md  hover:bg-blue-200 text-xs" onClick={logout}>
        <span className='text-gray-700 '>Deconnexion</span>
      </div>
      {
        showChangePassword ? <ChangePassword onClose={closeShowChangePassword}/> : <></>
      }
    </div>
  )
}

export default UserProfile