import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";


const firebaseConfig = {
    apiKey: "AIzaSyD8lrQYfQxKh_teGwuiOLKTcaKFHQM5zkw",
    authDomain: "cainotification.firebaseapp.com",
    projectId: "cainotification",
    storageBucket: "cainotification.appspot.com",
    messagingSenderId: "985679398235",
    appId: "1:985679398235:web:3561ed323a7db70dbc467e",
    measurementId: "G-06HLFD43RR"
};





// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export const requestPermission = () => {
    
    console.log('requesting user permission');
    Notification.requestPermission().then(permission => {
        console.log("dsafdsfds");
        if (permission === "granted") {
            console.log("granted");
             getToken(messaging,{
                vapidKey:"BN_SdAH2cre8iMzUkpKvxner48fnGFB51IXNQIB1uWbrkyOihI8Gg_znplIEzMyup5M460RiDAJtcTXQcCasbpQ"
             }).then(currentToken => {
                if (currentToken) {
                    console.log("chupapi:"+currentToken);

                } else {
                    console.log("Faiiiiiled");
                }
            }).catch(err => {
                console.log("dfsgdffsgs" + err);
            })
        } else {
            console.log("deniiiiiide");
        }
    })
};

requestPermission();

export const onMessageListener = () =>
    new Promise(resolve => {
        onMessage(messaging, payload => {
            resolve(payload);
        });
    }); 
