import React, { useEffect, useRef, useState } from 'react'
import { Button, Header, Input } from '../../components'
import { BsCalendar2Range, BsPlusCircleFill, BsTrash } from 'react-icons/bs';
import { Link, useNavigate } from 'react-router-dom';
import axiosClient from '../../axios-client';
import Loading from '../../components/Loading';
import { useStateContext } from '../../contexts/ContextProvider';
import { AiTwotoneEdit } from 'react-icons/ai';
import ActionButton from '../../components/ActionButton';
import { MdOutlineAccountBalanceWallet } from "react-icons/md";
import { IoIosSearch } from 'react-icons/io';
import Select from 'react-select';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { FaEdit, FaRegFileExcel } from 'react-icons/fa';
import AddEmploie from './AddEmploie';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import Alert from '../../components/Alert';
import EditEmploie from './EditEmploie';
import { LuMousePointerClick } from 'react-icons/lu';
import AddAbsence from '../absences/AddAbsence';

const EnseignantEmploie = () => {
  const navigate = useNavigate();
  const [emploies, setEmploies] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const { setToken, user } = useStateContext();
  const [jours, setJours] = useState([]);
  const [selectedJour, setSelectedJour] = useState(null);
  const [inputValueJour, setValueJour] = useState('');
  const [selectedEmploie, setSelectedEmploie] = useState(null);
  const [showAddAbsence, setShowAddAbsence] = useState(false);
  const tableRef = useRef(null);
  const [req, setReq] = useState({
    exp: '',
    jour: '',
  });

  const getJours = () => {
    axiosClient.get('/jours')
      .then((data) => {
        setJours(data.data.jours);
      }).catch(err => {
        const response = err.response;
        if (err.response.status === 401) {
          setToken(null);
        } else {
          if (response.status === 401) {
            setToken(null);
          }
          else {
            if (response.status === 403) {
              navigate('/403');
            } else {
              if (response.status === 404) {
                navigate('/404');
              }
              else {
                navigate('/500');
              }
            }
          }
        }
      })
  }
  const handleInputChangeJour = value => {
    setValueJour(value);
  };

  // handle selection


  // handle selection
  const handleChangeJour = value => {
    setSelectedJour(value);
    setReq({ ...req, jour: value.id });
  }


  const getEmploies = () => {
    setLoading(true);
    axiosClient.get('/monemploie?jour=' + req.jour)
      .then((data) => {
        setEmploies(data.data.emploies);
        setLoading(false);
        setErrors([]);
      }).catch(err => {
        const response = err.response;
        if (err.response.status === 401) {
          setToken(null);
        } else {
          if (response.status === 422) {
            setErrors(response.data.errors);
          }
          else {
            if (response.status === 403) {
              navigate('/403');
            } else {
              if (response.status === 404) {
                navigate('/404');
              }
              else {
                navigate('/500');
              }
            }
          }
        }
        setLoading(false);
      })
  }

  const closeAbsence=()=>{
    setShowAddAbsence(false);
    setSelectedEmploie(null);
  }

  useEffect(() => {
    getJours();
  }, []);
  return (
    <>
      <Header icon={<BsCalendar2Range />} category='Page' title='Emploies' />
      {errors &&
        Object.keys(errors).map(key => (
          <Alert key={key} message={errors[key][0]} />
        ))
      }
      <div className='flex-col '>
        <div className='flex flex-wrap md:flex-nowrap lg:flex-nowrap xl:flex-nowrap justify-between'>


          <div className="flex flex-wrap w-full justify-end items-end md:flex-nowrap lg:flex-nowrap xl:flex-nowrap gap-2">
            <div className="flex-col w-auto">
              <label className="block text-sm font-medium leading-6 text-gray-900">Jour <span className='text-red-500'>*</span></label>
              <Select className='mt-2 outline-1 ' placeholder="Jour..." cacheOptions defaultOptions value={selectedJour} getOptionLabel={e => (e.libelleFr+' '+e.libelleAr)} getOptionValue={e => e.id} options={jours} onInputChange={handleInputChangeJour} onChange={handleChangeJour} />
            </div>

            <div className='mt-8'>
              <Button onClick={() => getEmploies()} color="bg-yellow-500" icon={<IoIosSearch />} size="20" text="Chercher" textColor="text-white" />
            </div>
            <div className="mt-8">
              <DownloadTableExcel
                filename="Inscriptions"
                sheet="inscriptions"
                currentTableRef={tableRef.current}
              >
                <Button color="bg-green-500" icon={<FaRegFileExcel />} size="20" text="Exporter" textColor="text-white" />

              </DownloadTableExcel>
            </div>
          </div>
        </div>

        <div className='mt-3 w-full overflow-x-auto'>
          <table ref={tableRef} className='table-auto'>
            <thead>
              <tr>
                <th>Classe</th>
                <th>Matiere</th>
                <th>Heure Debut</th>
                <th>Heure Fin</th>
                <th>Actions</th>
              </tr>
            </thead>
            {loading ? <tbody><tr className='content-center items-center'><td className='content-center items-center' colSpan="9"><Loading /></td></tr></tbody> :
              (

                <tbody>
                  {emploies && emploies.map(o => (
                    <tr key={o.id}>
                      <td>{o.classe.libelle}</td>
                      <td>{o.matiere.matiere.libelle}</td>
                      <td>{o.heureDebut}</td>
                      <td>{o.heureFin}</td>
                      <td>
                        <div className="flex justify-center w-full gap-2 items-center">
                          <ActionButton onClick={() => {
                            setSelectedEmploie(o);
                            setShowAddAbsence(true);
                          }} color="bg-red-500" icon={<LuMousePointerClick />} textColor="text-white" title="Relever Absences" />
                        </div>
                      </td>
                    </tr>
                  ))

                  }
                </tbody>
              )
            }
          </table>
        </div>
        
        {
          showAddAbsence ? <AddAbsence selectedEmploie={selectedEmploie} onClose={closeAbsence} /> : <></>
        }
      </div>
    </>
  )
}

export default EnseignantEmploie