import React, { useEffect, useRef, useState } from 'react'
import { Button, Header, Input } from '../../components'
import { BsPlusCircleFill, BsTrash } from 'react-icons/bs';
import { Link, useNavigate } from 'react-router-dom';
import axiosClient from '../../axios-client';
import Loading from '../../components/Loading';
import { useStateContext } from '../../contexts/ContextProvider';
import { AiTwotoneEdit } from 'react-icons/ai';
import ActionButton from '../../components/ActionButton';
import { MdOutlineAccountBalanceWallet } from "react-icons/md";
import { IoIosSearch } from 'react-icons/io';
import Select from 'react-select';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { FaEdit, FaEye, FaRegFileExcel } from 'react-icons/fa';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import AddDevoir from './AddDevoir';
import Alert from '../../components/Alert';
import EditDevoir from './EditDevoir';
import VoirDevoir from './VoirDevoir';
import { PiNotePencilBold } from 'react-icons/pi';

const MesDevoirs = () => {
  const navigate = useNavigate();
  const [devoirs, setDevoirs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);
  const { setToken, user } = useStateContext();

  const[selectedDevoir,setSelectedDevoir]=useState(null);
  const [showAdd, setShowAdd] = useState(false);
  const [showView, setShowView] = useState(false);

  const tableRef = useRef(null);
  const [req, setReq] = useState({
    classe: '',
    scolaire: '',
    trimestre: '',
  });

  

    const getDevoirs = () => {
    setLoading(true);
    axiosClient.get('/mesdevoirs')
      .then((data) => {
        setDevoirs(data.data.devoirs);
        setLoading(false);
      }).catch(err => {
        const response = err.response;
        if (err.response.status === 401) {
          setToken(null);
        } else {
          if (response.status === 422) {
            setErrors(response.data.errors);
            
          }
          else {
            if (response.status === 403) {
              navigate('/403');
            } else {
              if (response.status === 404) {
                navigate('/404');
              }
              else {
                navigate('/500');
              }
            }
          }
        }
        setLoading(false);
      })
    
  }

  const closeShow=()=>{
    setShowView(false);
    setSelectedDevoir(null);
  }
  

  useEffect(() => {
   getDevoirs();
  }, []);
  return (
    <>
      <Header icon={<PiNotePencilBold />} category='Page' title='Devoirs' />
      {errors &&
                Object.keys(errors).map(key => (
                    <Alert key={key} message={errors[key][0]} />
                ))
      }
      <div className='flex-col '>
        <div className='flex flex-wrap md:flex-nowrap lg:flex-nowrap xl:flex-nowrap justify-between'>
          

          <div className="flex flex-wrap md:flex-nowrap lg:flex-nowrap xl:flex-nowrap gap-2">
            
            
            <div className="mt-8">
              <DownloadTableExcel
                filename="Inscriptions"
                sheet="inscriptions"
                currentTableRef={tableRef.current}
              >
                <Button color="bg-green-500" icon={<FaRegFileExcel />} size="20" text="Exporter" textColor="text-white" />

              </DownloadTableExcel>
            </div>
          </div>
        </div>

        <div className='mt-3 w-full overflow-x-auto'>
          <table ref={tableRef} className='table-auto'>
            <thead>
              <tr>
                <th>Classe</th>
                <th>Date</th>
                <th>Matiere</th>
                <th>Heure Debut</th>
                <th>Heure Fin</th>
                <th>Enseignant</th>
                <th>Actions</th>
              </tr>
            </thead>
            {loading ? <tbody><tr className='content-center items-center'><td className='content-center items-center' colSpan="9"><Loading /></td></tr></tbody> :
              (

                <tbody>
                  {devoirs && devoirs.map(o => (
                    <tr className={o.color} key={o.id}>
                      <td>{o.classe.libelle}</td>
                      <td>{o.dateDevoir}</td>
                      <td>{o.matiere.libelle}</td>
                      <td>{o.heureDebut}</td>
                      <td>{o.heureFin}</td>
                      <td>{o.enseignant.nom}</td>
                      <td>
                        <div className="flex justify-center w-full items-center gap-2">
                          <ActionButton onClick={() =>{
                            setSelectedDevoir(o);
                            setShowView(true);
                          }} color="bg-green-500" icon={<FaEye />} textColor="text-white" title="Voir" />
                          
                        </div>
                      </td>
                    </tr>
                  ))

                  }
                </tbody>
              )
            }
          </table>
        </div>
        
        {
          showView ? <VoirDevoir selectedDevoir={selectedDevoir} onClose={closeShow} /> : <></>
        }
      </div>
    </>
  )
}

export default MesDevoirs