import { Navigate, createBrowserRouter } from "react-router-dom";
import DefaultLayout from "./layouts/DefaultLayout";
import GuestLayout from "./layouts/GuestLayout";
import Login from "./pages/Login";
import Forbiden from "./pages/Forbiden";
import NotFound from "./pages/NotFound";
import ServerError from "./pages/ServerError";
import Users from "./pages/User/Users";
import AddUser from "./pages/User/AddUser";
import EditUser from "./pages/User/EditUser";
import Home from "./pages/Home";
import Classes from "./pages/classe/Classes";
import Matieres from "./pages/matieres/Matieres";
import Inscriptions from "./pages/Inscriptions/Inscriptions";
import AddInscription from "./pages/Inscriptions/AddInscription";
import EditInscription from "./pages/Inscriptions/EditInscription";
import Compte from "./pages/Inscriptions/Compte";
import Factures from "./pages/factures/Factures";
import Situation from "./pages/situaction/Situation";
import Depenses from "./pages/depense/Depenses";
import Alimentations from "./pages/alimentation/Alimentations";
import Historiques from "./pages/historique/Historiques";
import Sorties from "./pages/sortie/Sorties";
import Bulletins from "./pages/bulletins/Bulletins";
import Coefficients from "./pages/coefficients/Coefficients";
import Examens from "./pages/examens/Examens";
import Devoirs from "./pages/devoirs/Devoirs";
import Emploies from "./pages/emploies/Emploies";
import MesExamens from "./pages/examens/MesExamens";
import MesDevoirs from "./pages/devoirs/MesDevoirs";
import EnseignantEmploie from "./pages/emploies/EnseignantEmploie";
import Absences from "./pages/absences/Absences";
import Observations from "./pages/observations/Observations";
const router=createBrowserRouter([
    {
        path:'/',
        element:<DefaultLayout/>,
        children:[
            {
                path:'/',
                element:<Navigate to="/home"/>
            },
            {
                path:'/home',
                element:<Home/>
            },
            
            {
                path:'/403',
                element:<Forbiden/>
            },
            {
                path:'/404',
                element:<NotFound/>
            },
            
            {
                path:'/users',
                element:<Users/>
            },
            {
                path:'/user/new',
                element:<AddUser/>
            },
            {
                path:'/user/:id',
                element:<EditUser/>
            },
            {
                path:'/classes',
                element:<Classes/>
            },
            {
                path:'/matieres',
                element:<Matieres/>
            },
            {
                path:'/inscriptions',
                element:<Inscriptions/>
            },
            {
                path:'/inscription/new',
                element:<AddInscription/>
            },
            {
                path:'/inscription/:id',
                element:<EditInscription/>
            },
            {
                path:'/inscriptionAccount/:id',
                element:<Compte/>
            },
            {
                path:'/factures',
                element:<Factures/>
            },
            {
                path:'/situations',
                element:<Situation/>
            },
            {
                path:'*',
                element:<NotFound/>
            },
            {
                path:'/depenses',
                element:<Depenses/>
            }, 
            {
                path:'/alimentations',
                element:<Alimentations/>
            },
            {
                path:'/sorties',
                element:<Sorties/>
            }, 
            {
                path:'/historiques',
                element:<Historiques/>
            },
            {
                path:'/bulletins',
                element:<Bulletins/>
            },
            {
                path:'/coefficients',
                element:<Coefficients/>
            }, 
            {
                path:'/examens',
                element:<Examens/>
            },
            {
                path:'/mesexamens',
                element:<MesExamens/>
            },
            {
                path:'/devoirs',
                element:<Devoirs/>
            },
            {
                path:'/mesdevoirs',
                element:<MesDevoirs/>
            },
            {
                path:'/emploies',
                element:<Emploies/>
            },
            {
                path:'/monemploie',
                element:<EnseignantEmploie/>
            },
            {
                path:'/absences',
                element:<Absences/>
            },
            {
                path:'/observations',
                element:<Observations/>
            },
        ]
    },
    {
        path:'/',
        element:<GuestLayout/>,
        children:[
            {
                path:'/login',
                element:<Login/>
            },
            {
                path:'/404',
                element:<NotFound/>
            },
            {
                path:'*',
                element:<NotFound/>
            },
            
            
        ]
    },
    {
        path:'/500',
        element:<ServerError/>
    },
    {
        path:'*',
        element:<NotFound/>
    }
    
    
])

export default router;