import React from 'react'
import Error500 from '../data/errorServer503.gif'
import { Button } from '../components'
import { IoHome } from "react-icons/io5";
import { Link } from 'react-router-dom';
const ServerError = () => {
  return (
    <div className='flex flex-col justify-center items-center p-7'>
      
      <img className="mx-auto h-96 w-auto" src={Error500} alt="Your Company" />
      <div className="p-7">
      <Link to="/">
        <Button color="bg-gray-500" icon={<IoHome />} size="72" text="Accueil" textColor="text-white" />
      </Link>
      </div>
    </div>
  )
}

export default ServerError