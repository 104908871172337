import React, { useEffect, useRef, useState } from 'react'
import { Button, Header, Input } from '../../components'
import { BsPlusCircleFill, BsTrash } from 'react-icons/bs';
import { Link, useNavigate } from 'react-router-dom';
import axiosClient from '../../axios-client';
import Loading from '../../components/Loading';
import { useStateContext } from '../../contexts/ContextProvider';
import { AiTwotoneEdit } from 'react-icons/ai';
import ActionButton from '../../components/ActionButton';
import { MdEditNote, MdOutlineAccountBalanceWallet } from "react-icons/md";
import { IoIosSearch } from 'react-icons/io';
import Select from 'react-select';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { FaEdit, FaEye, FaRegFileAlt, FaRegFileExcel } from 'react-icons/fa';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import Alert from '../../components/Alert';
import { VscDebugRerun } from "react-icons/vsc";
import ShowBulletinFondamental from './ShowBulletinFondamental';
import ShowBulletinSecondaire from './ShowBulletinSecondaire';

const Bulletins = () => {
  const navigate = useNavigate();
  const [bulletins, setBulletins] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);
  const { setToken, user } = useStateContext();

  const [classes, setClasses] = useState([]);
  const [selectedClasse, setSelectedClasse] = useState(null);
  const [inputValueClasse, setValueClasse] = useState('');

  const [trimestres, setTrimestres] = useState([]);
  const [selectedTrimestre, setSelectedTrimestre] = useState(null);
  const [inputValueTrimestre, setValueTrimestre] = useState('');

  const [scolaires, setScolaires] = useState([]);
  const [selectedScolaire, setSelectedScolaire] = useState(null);
  const [inputValueScolaire, setValueScolaire] = useState('');

  const [selectedBulletin, setSelectedBulletin] = useState(null);
  const [showBulletinFondamental, setShowBulletinFondamental] = useState(false);
  const [showBulletinSecondaire, setShowBulletinSecondaire] = useState(false);

  const [showView, setShowView] = useState(false);

  const tableRef = useRef(null);
  const [req, setReq] = useState({
    classe: '',
    scolaire: '',
    trimestre: '',
  });

  const closeShowBulletin = () => {
    setShowBulletinSecondaire(false);
    setShowBulletinFondamental(false);
    setSelectedBulletin(null);
  }


  const getClasses = () => {
    axiosClient.get('/classes')
      .then((data) => {
        setClasses(data.data.classes);
      }).catch(err => {
        const response = err.response;
        if (err.response.status === 401) {
          setToken(null);
        } else {
          if (response.status === 401) {
            setToken(null);
          }
          else {
            if (response.status === 403) {
              navigate('/403');
            } else {
              if (response.status === 404) {
                navigate('/404');
              }
              else {
                navigate('/500');
              }
            }
          }
        }
      })
  }
  const handleInputChangeClasse = value => {
    setValueClasse(value);
  };

  // handle selection


  // handle selection
  const handleChangeClasse = value => {
    setSelectedClasse(value);
    setReq({ ...req, classe: value.id });
  }

  const handleChangeTrimestre = value => {
    setSelectedTrimestre(value);
    setReq({ ...req, trimestre: value.id });
  }

  const handleInputChangeTrimestre = value => {
    setValueTrimestre(value);
  };

  const handleChangeScolaire = value => {
    setSelectedScolaire(value);
    setReq({ ...req, scolaire: value.id });
  }

  const handleInputChangeScolaire = value => {
    setValueScolaire(value);
  };

  const getTrimestres = () => {
    axiosClient.get('/trimestres')
      .then((data) => {
        setTrimestres(data.data.trimestres);
      }).catch(err => {
        navigate('/500');
      })
  }

  const getScolaires = () => {
    axiosClient.get('/getScolaires')
      .then((data) => {
        setScolaires(data.data.scolaires);
      }).catch(err => {
        navigate('/500');
      })
  }
  const getBulletins = () => {
    setLoading(true);
    axiosClient.get('/bulletins?scolaire=' + req.scolaire + '&classe=' + req.classe + '&trimestre=' + req.trimestre)
      .then((data) => {
        setBulletins(data.data.bulletins);
        setLoading(false);
      }).catch(err => {
        const response = err.response;
        if (err.response.status === 401) {
          setToken(null);
        } else {
          if (response.status === 422) {
            setErrors(response.data.errors);

          }
          else {
            if (response.status === 403) {
              navigate('/403');
            } else {
              if (response.status === 404) {
                navigate('/404');
              }
              else {
                navigate('/500');
              }
            }
          }
        }
        setLoading(false);
      })

  }

  const genererBulletin = () => {
    setLoading(true);
    axiosClient.get('/bulletin-generer?scolaire=' + req.scolaire + '&classe=' + req.classe + '&trimestre=' + req.trimestre)
      .then((data) => {
        setBulletins(data.data.bulletins);
        toast.success('Opération effectuée avec succès!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setLoading(false);
      }).catch(err => {
        const response = err.response;
        if (err.response.status === 401) {
          setToken(null);
        } else {
          if (response.status === 422) {
            setErrors(response.data.errors);

          }
          else {
            if (response.status === 403) {
              navigate('/403');
            } else {
              if (response.status === 404) {
                navigate('/404');
              }
              else {
                navigate('/500');
              }
            }
          }
        }
        setLoading(false);
      })

  }




  useEffect(() => {
    getClasses();
    getTrimestres();
    getScolaires();
  }, []);
  return (
    <>
      <Header icon={<FaRegFileAlt />} category='Page' title='Bulletins' />
      {errors &&
        Object.keys(errors).map(key => (
          <Alert key={key} message={errors[key][0]} />
        ))
      }
      <div className='flex-col '>
        <div className='flex flex-wrap md:flex-nowrap lg:flex-nowrap xl:flex-nowrap justify-end items-center'>

          <div className="flex flex-wrap md:flex-nowrap lg:flex-nowrap xl:flex-nowrap items-center gap-2">
            <div className="flex-col w-auto">
              <label className="block text-sm font-medium leading-6 text-gray-900">Scolaire <span className='text-red-500'>*</span></label>
              <Select className='mt-2 outline-1 ' placeholder="Scolaire..." cacheOptions defaultOptions value={selectedScolaire} getOptionLabel={e => e.code} getOptionValue={e => e.id} options={scolaires} onInputChange={handleInputChangeScolaire} onChange={handleChangeScolaire} />
            </div>
            <div className="flex-col w-auto">
              <label className="block text-sm font-medium leading-6 text-gray-900">Classe <span className='text-red-500'>*</span></label>
              <Select className='mt-2 outline-1 ' placeholder="Classe..." cacheOptions defaultOptions value={selectedClasse} getOptionLabel={e => e.libelle} getOptionValue={e => e.id} options={classes} onInputChange={handleInputChangeClasse} onChange={handleChangeClasse} />
            </div>
            <div className="flex-col w-auto">
              <label className="block text-sm font-medium leading-6 text-gray-900">Trimestre <span className='text-red-500'>*</span></label>
              <Select className='mt-2 outline-1 ' placeholder="Trimestre..." cacheOptions defaultOptions value={selectedTrimestre} getOptionLabel={e => e.code} getOptionValue={e => e.id} options={trimestres} onInputChange={handleInputChangeTrimestre} onChange={handleChangeTrimestre} />
            </div>
            <div className='mt-8'>
              <Button onClick={() => genererBulletin()} color="bg-blue-500" icon={<VscDebugRerun />} size="20" text="Générer" textColor="text-white" />
            </div>
            <div className='mt-8'>
              <Button onClick={() => getBulletins()} color="bg-yellow-500" icon={<IoIosSearch />} size="20" text="Chercher" textColor="text-white" />
            </div>
            <div className="mt-8">
              <DownloadTableExcel
                filename="Inscriptions"
                sheet="inscriptions"
                currentTableRef={tableRef.current}
              >
                <Button color="bg-green-500" icon={<FaRegFileExcel />} size="20" text="Exporter" textColor="text-white" />

              </DownloadTableExcel>
            </div>
          </div>
        </div>
        <div className='mt-3 w-full overflow-x-auto'>
          <table ref={tableRef} className='table-auto'>
            <thead>
              <tr>
                <th>Matricule</th>
                <th>Élève</th>
                <th>Tel Parent</th>
                <th>Total</th>
                <th>Moyenne</th>
                <th>Actions</th>
              </tr>
            </thead>
            {loading ? <tbody><tr className='content-center items-center'><td className='content-center items-center' colSpan="9"><Loading /></td></tr></tbody> :
              (

                <tbody>
                  {bulletins && bulletins.map(o => (
                    <tr>
                      <td>{o.inscription.matricule}</td>
                      <td>{o.inscription.eleve}</td>
                      <td>{o.inscription.telParent}</td>
                      <td>{o.total}/{o.global}</td>
                      <td>{o.roundMg}</td>
                      <td>
                        <div className="flex justify-center w-full items-center gap-2">
                          <ActionButton onClick={() => {
                            setSelectedBulletin(o);
                            ((o.inscription.classe.niveau === 'PRM' || o.inscription.classe.niveau === 'JRDN') ? setShowBulletinFondamental(true) : setShowBulletinSecondaire(true))
                          }} color="bg-green-500" icon={<FaEye />} textColor="text-white" title="Voir" />
                        </div>
                      </td>
                    </tr>
                  ))

                  }
                </tbody>
              )
            }
          </table>
        </div>
        {
          showBulletinFondamental ? <ShowBulletinFondamental selectedBulletin={selectedBulletin} onClose={closeShowBulletin} /> : <></>
        }
        {
          showBulletinSecondaire ? <ShowBulletinSecondaire selectedBulletin={selectedBulletin} onClose={closeShowBulletin} /> : <></>
        }
      </div>
    </>
  )
}

export default Bulletins