import React from 'react'
import { NumericFormat } from 'react-number-format'

const NumberFormat = ({valeur}) => {
    return (
        <>
            <NumericFormat
                value={valeur}
                floatValue={valeur}
                thousandSeparator=" "
                displayType="text"
                allowNegative
                decimalScale={3}
                renderText={(value) => <>{value}</>}
            />
        </>
    )
}

export default NumberFormat