import React, { useEffect, useRef, useState } from 'react'
import { Button, Header, Input } from '../../components'
import Alert from '../../components/Alert'
import { IoIosSearch } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import axiosClient from '../../axios-client';
import { useStateContext } from '../../contexts/ContextProvider';
import { toast } from 'react-toastify';
import Loading from '../../components/Loading';
import ActionButton from '../../components/ActionButton';
import { FaEye } from 'react-icons/fa';
import VoirHistorique from './VoirHistorique';
const Historiques = () => {
    const [errors, setErrors] = useState(null);
    const [loading, setLoading] = useState(false);
    const [historiques, setHistoriques] = useState([]);
    const [showVoirHistorique, setShowVoirHistorique] = useState(false);
    const [selectedHistorique,setSelectedHistorique]=useState(null);
    const { token, setToken, user } = useStateContext();
    const tableRef = useRef();
    const [req, setReq] = useState({
        DateDebut: '',
        DateFin: '',
        exp: '',
    });
    const navigate = useNavigate();
    useEffect(() => {

    }, [])
    const closeShowHistorique = () => {
        setShowVoirHistorique(false);
        setSelectedHistorique(null);
    }
    const getHistoriques = () => {
        setLoading(true);
        axiosClient.get('/getHistoriques?DateDebut=' + req.DateDebut + '&DateFin=' + req.DateFin + '&exp=' + req.exp)
            .then(response => {
                setHistoriques(response.data.historiques);
                setLoading(false);
                setErrors(null);
            }).catch(err => {
                const response = err.response;
                if (response.status === 401) {
                    setToken(null);
                }
                else {
                    if (response.status === 422) {
                        setErrors(response.data.errors);
                        setLoading(false);
                        toast.error('Merci de verifier les informations a saisies!', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    } else {
                        if (response.status === 403) {
                            navigate('/403');
                        } else {
                            if (response.status === 404) {
                                navigate('/404');
                            }
                            else {
                                navigate('/500');
                            }
                        }
                    }

                }
            })
    }

    return (
        <>
            <Header title="Historiques" />
            {errors &&
                Object.keys(errors).map(key => (
                    <Alert key={key} message={errors[key][0]} />
                ))
            }
            <div className='flex gap-2 w-full flex-wrap md:flex-nowrap lg:flex-nowrap xl:flex-nowrap justify-between items-center'>
                <Input label="Date debut" type="date" value={req.DateDebut} onChange={ev => setReq({ ...req, DateDebut: ev.target.value })} required="required" />
                <Input label="Date fin" type="date" value={req.DateFin} onChange={ev => setReq({ ...req, DateFin: ev.target.value })} required="required" />
                <Input label="Expression" type="text" onChange={ev => setReq({ ...req, exp: ev.target.value })} />
                <div className=' flex gap-2 mt-8'>
                    <Button onClick={() =>getHistoriques()} color="bg-yellow-500" icon={<IoIosSearch />} size="20" text="Chercher" textColor="text-white" />
                </div>
            </div>
            
            <div className='flex mt-3 overflow-auto'>
                <table ref={tableRef} className='table-auto' >
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Table</th>
                            <th>Type</th>
                            <th>Pre Valeur</th>
                            <th>Post Valeur</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    {loading ? <tbody><tr className='content-center items-center'><td className='content-center items-center' colSpan="6"><Loading /></td></tr></tbody> :
                        <tbody>
                            {historiques.map(o => (
                                <tr>
                                    <td>{o.dateHistorique}</td>
                                    <td>{o.table}</td>
                                    <td>{o.action}</td>
                                    <td>{o.preValeur.substring(0,10)}...</td>
                                    <td>{o.postValeur && o.postValeur.substring(0,10)}...</td>
                                    <td><ActionButton onClick={()=>{ 
                                      setSelectedHistorique(o);
                                      setShowVoirHistorique(true);
                                     }} color="bg-green-500" icon={<FaEye />} textColor="text-white" title="Voir" />
                                    </td>
                                </tr>
                              ))
                            }
                        </tbody>
                    }
                </table>
                {
                  showVoirHistorique ? <VoirHistorique historique={selectedHistorique} onClose={closeShowHistorique}/> :<></>
                }
            </div>
        </>
    )
}

export default Historiques