import React from 'react'

const BulletinElement = ({ left, center,right, color }) => {
    return (
        <div className="flex  border-2 border-black rounded-xl py-1 px-3">
            {left } {center} {right}
        </div>
    )
}

export default BulletinElement